import { Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getStudentsMessages } from '../../../api/firebase/notification.api'
import { selectStudent } from '../../../api/states/student.state'
import { selectStudentNotification, updateStudentNotifications } from '../../../api/states/studentNotifications.state'
import NotificationCard from '../../../components/Notifications/NotificationCard.component'
import { STUDENTLOGIN, STUDENTNOTIFICATIONDETAIL } from '../../../constants/routes.constant'
import { Message } from '../../../interfaces/notification.interface'



const Notifications: React.FC = () => {


  const [notifications, setNotifications] = useState<Message[]>([])
  const student = useSelector(selectStudent)
  const navigate = useNavigate()


  useEffect(() => {
    if (student) {
      getStudentsMessages(student.email, (messages) => {
        getStudentsMessages(student.form, (publicMessages) => {
          setNotifications([...messages, ...publicMessages].sort((a, b) => a.timestamp > b.timestamp ? 1 : -1))
        })
      })

    }
  }, [student])

  return (
    <div className='py-10 px-5 md:px-32 min-h-screen'>
      <div>
        <div className="text-xl mb-7">Your Notifications</div>
        {
          notifications.map((notification, index) => {
            return (
              <div onClick={() => navigate(STUDENTNOTIFICATIONDETAIL, { state: { message: notification } })}>
                <NotificationCard data={notification} />
              </div>
            )
          })
        }
        {!student && <Link to={STUDENTLOGIN}><div className="mt-10">
          <button className='button btn btn-blue-500 w-auto px-4 '> <BiLock className='text-[20px] mr-3 inline-block' />  Login to Access</button>
        </div></Link>}
      </div>
    </div>
  )
}

export default Notifications