import React from 'react'
import { BsBarChartLineFill } from 'react-icons/bs'
import { FaMedal } from 'react-icons/fa'
import { BiSelectMultiple } from 'react-icons/bi'
import { HiUserGroup } from 'react-icons/hi'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaRegWindowRestore } from 'react-icons/fa'
import { BiLogOut } from 'react-icons/bi'
import { Link, useLocation } from 'react-router-dom'
import { ADMINBLOG, ADMINNOTIFICATIONS, ADMINPROFILE, ADMINRESOURCES, ADMINSTAFFS, ADMINSTUDENTS, COMPETITIONADMIN, LANDING, STATISTICS } from '../../constants/routes.constant'
import { Button } from '@mui/material'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiChatBubble, GiTeamIdea } from 'react-icons/gi'

const Menu: React.FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {

    const location = useLocation()
    const path = location.pathname


    return (
        <div className='p-2 py-4 bg-dark md:min-h-screen h-full'>
            <div className="text-xl px-2 pb-10 py-7 border-b border-gray-600 relative">
                <span className='text-white font-bold  mr-2'>KC Admin</span>
                <span className='text-gray1'>Dashboard</span>
                <Button onClick={toggleMenu} sx={{ color: "white" }} className='float-right lg:opacity-0'><AiOutlineCloseCircle className=' text-[20px]' /> </Button>
            </div>

            <ul id="menu" className='mt-8 sticky z-50 top-0'>
                <li className='p-1 py-2 bg-gray-800 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={STATISTICS} className={'ml-2 flex text-white ' + (path.match('statistics') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><BsBarChartLineFill /></span>
                        <span className='p-1'>Statistics</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-800 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={COMPETITIONADMIN} className={'ml-2 flex ' + (path.match('competition') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><FaMedal /></span>
                        <span className='p-1'>Competitions</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINRESOURCES} className={'ml-2 flex ' + (path.match('resources') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><BiSelectMultiple /></span>
                        <span className='p-1'>Resources</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINSTUDENTS} className={'ml-2 flex ' + (path.match('students') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><HiUserGroup /></span>
                        <span className='p-1'>Students</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINPROFILE} className={'ml-2 flex ' + (path.match('profile') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><BsFillPersonFill /></span>
                        <span className='p-1'>profile</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINBLOG} className={'ml-2 flex ' + (path.match('blob') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><FaRegWindowRestore /></span>
                        <span className='p-1'>Blog</span>
                    </Link>
                </li>
                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINNOTIFICATIONS} className={'ml-2 flex ' + (path.match('blob') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><GiChatBubble /></span>
                        <span className='p-1'>Messages</span>
                    </Link>
                </li>
                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <Link to={ADMINSTAFFS} className={'ml-2 flex ' + (path.match('blob') ? 'text-white' : 'text-gray-300')}>
                        <span className='p-2 '><GiTeamIdea /></span>
                        <span className='p-1'>Staff</span>
                    </Link>
                </li>

                <li className='p-1 py-2 bg-gray-700 rounded bg-opacity-0 hover:bg-opacity-50'>
                    <a href="#" className='ml-2 flex text-gray-300'>
                        <span className='p-2 '><BiLogOut /></span>
                        <span className='p-1'>Logout</span>
                    </a>
                </li>


            </ul>
        </div>

    )
}

export default Menu

