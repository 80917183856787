import { Stream, Subject } from "../../interfaces/competition.interface";



/**
 * 
 * @param name - The stream name 
 */
export function generateStreamDetail(name: string): Stream {

    return ({
        id: name.toLowerCase(),
        name: name,
        subjects: []

    })

}


export function addSubjectsToStream(stream: Stream, subject: Subject): Stream {
    return ({
        id: stream.id,
        name: stream.name,
        subjects: [...stream.subjects, subject]
    })
}

export function generateSubjectDetail(name: string): Subject {
    return ({
        id: name.toLowerCase(),
        name: name
    })

}