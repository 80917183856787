import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Navigation from './components/Navigation/Navigation.component';
import "./App.css"
import { useDispatch } from 'react-redux';
import { updateStudent } from './api/states/student.state';
import { updateCompetitions } from './api/states/competitions.state';
import { getAllCompetitions } from './api/firebase/competition.api';
import { updateStudents } from './api/states/students.state';
import { getAllStudents } from './api/firebase/auth.api';
import { updateAdmin } from './api/states/admin.state';
import { getStudentsMessages } from './api/firebase/notification.api';
import { Student } from './interfaces/auth.interface';
import { updateStudentNotifications } from './api/states/studentNotifications.state';




function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    initializeStates()
  }, [])

  function initializeStates() {

    //student state
    const student = localStorage.getItem('student')
    student && dispatch(updateStudent(JSON.parse(student as string)))


    const competitions = localStorage.getItem("competitions")
    competitions && dispatch(updateCompetitions(JSON.parse(competitions as string)))

    const students = localStorage.getItem("students")
    students && dispatch(updateStudents(JSON.parse(students as string)))


    const admin = localStorage.getItem("admin")
    students && dispatch(updateAdmin(JSON.parse(admin as string)))

    setTimeout(() => {
      getAllCompetitions((competitions) => dispatch(updateCompetitions(competitions)))
      getAllStudents((students) => dispatch(updateStudents(students)))
    }, 2000);

  }

  return (
    <div>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
