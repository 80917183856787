import { Alert, CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { signInStudent } from '../../../api/firebase/auth.api'
import { updateAdmin } from '../../../api/states/admin.state'
import { updateStudent } from '../../../api/states/student.state'
import coverImage from '../../../assets/images/COVER IMAGE.png'
import { STUDENTLOGIN, STUDENTREGISTRATION, STUDENTSIGNUP } from '../../../constants/routes.constant'
import { defaultStudent } from '../../../data/default.data'
import { Admin, Student } from '../../../interfaces/auth.interface'

const Login: React.FC = () => {
  const [student, setStudent] = useState(defaultStudent)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()


  async function onSubmit(e: any) {
    e.preventDefault()
    window.scrollTo(0, 0)
    setLoading(true)
    setError("")
    setSuccess('')
    const redirect = localStorage.getItem("redirect")
    try {
      const result = await signInStudent(student.email, student.password)
      console.log(result)

      setSuccess("successfully Logged in")
      if ((result as Admin).type) {
        dispatch(updateAdmin(result as Admin))
        navigate("/admin")
        return;
      }
      dispatch(updateStudent(result as Student))
      localStorage.removeItem("redirect")
      redirect ? navigate(redirect) : navigate("/")

    } catch (err: any) {
      if (typeof err == "string") {
        setError(err)
      } else {
        setError(err.message)
      }
    }
    setLoading(false)
  }

  return (
    <div className='p-5 min-h-screen' >
      <div className='grid sm:grid-cols-3'>
        <div className='h-0 md:h-auto'>

        </div>
        <div className='col-span-1'>
          <div className="p-3">
            {success && <Alert severity='info'>{success}</Alert>}
            {error && <Alert severity='error'>{error}</Alert>}
          </div>
          <div className='mx-10 my-14 font-light text-3xl '>Login  {loading && <CircularProgress />}</div>

          <form onSubmit={onSubmit} action="">
            <div className='mb-12 w-full'>
              <TextField disabled={loading} value={student.email} onChange={(e) => setStudent({ ...student, email: e.target.value })} className='w-full' id="outlined-basic" label="Email" variant="outlined" />
            </div>
            <div className=''>
              <TextField disabled={loading} value={student.password} onChange={(e) => setStudent({ ...student, password: e.target.value })} className='w-full' id="outlined-basic" label="Password" type={'password'} variant="outlined" />
            </div>
            {/* <div className="py-6">
              <label className="text-sm font-light cursor-pointer underline text-blue-500">forgot password?</label>
            </div> */}
            <div>
              <button disabled={loading} type='submit' className='button rounded-md my-14 w-full'>Login</button>
            </div>
          </form>
          <div className="h-6"></div>
          <div className="py-6 text-center">
            <Link to={STUDENTSIGNUP} className=""><label className="text-sm font-light cursor-pointer underline text-blue-500">I don't have an account</label></Link>
          </div>
        </div>
        <div>

        </div>
      </div>

    </div>
  )
}

export default Login