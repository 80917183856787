import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { Competition } from '../../interfaces/competition.interface'

// Define a type for the slice state
interface CompetitionsState {
    value: Competition[]
}

// Define the initial state using that type
const initialState: CompetitionsState = {
    value: [],
}

export const competitionsState = createSlice({
    name: 'competitions',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        updateCompetitions: (state, action: PayloadAction<Competition[]>) => {
            state.value = action.payload
            localStorage.setItem('competitions', JSON.stringify(action.payload))
        },
    },
})

export const { updateCompetitions } = competitionsState.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCompetition = (state: RootState) => state.competitions.value

export default competitionsState.reducer