import { Accordion, AccordionDetails, AccordionSummary, Button, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { SiAddthis } from 'react-icons/si'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllMessageChannels } from '../../../api/firebase/notification.api'
import { selectStudent } from '../../../api/states/student.state'
import { selectStudents } from '../../../api/states/students.state'
import MessageChannelCard from '../../../components/Notifications/MessageChannelCard.component'
import { ADMINNCOMPOSENOTIF } from '../../../constants/routes.constant'
import { defaultClasses } from '../../../data/default.data'

const AdminNotification = () => {
    const students = useSelector(selectStudents)
    const [channels, setChannels] = useState<string[]>([])


    useEffect(() => {

        getAllMessageChannels((channels) => {
            setChannels([...channels.map(chan => chan.id)])
            console.log(channels)
        })

        return () => {

        }
    }, [])




    return (
        <div className='min-h-screen'>

            <div className="p-10">
                <div className="text-3xl">Notifications
                    <Link to={ADMINNCOMPOSENOTIF}>
                        <Button className='float-right' variant='outlined'> <SiAddthis className='mr-1' /> Compose</Button>
                    </Link>
                </div>
                <div className="my-20">

                    <div className=''>
                        <div className="my-3 text-xl">Public Messages</div>
                        {
                            channels.filter((chan) => (defaultClasses.includes(chan))).map((channel, index) => {

                                return (<MessageChannelCard channel={channel} />)

                            })
                        }
                    </div>
                    <div className='mt-10'>
                        <div className="my-3 text-xl">Private Messages</div>
                        {
                            channels.filter((chan) => (!defaultClasses.includes(chan))).map((channel, index) => {

                                const student = students.find((st, index) => st.email == channel)!;

                                return (<MessageChannelCard channel={channel} student={student} />)


                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNotification