import { TextField } from '@mui/material'
import React from 'react'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaTwitterSquare, FaGooglePlusSquare } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { SiFacebook } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { LANDINGABOUT, LANDINGBLOG, LANDINGSTEMCOMP, STUDENTNOTIFICATIONS, STUDENTRESOURCES } from '../../constants/routes.constant'

const LandingFooter = () => {
    return (
        <>
            <div className='bg-dark text-light w-full'>
                <div className='grid md:grid-cols-2 pt-10 gap-5'>
                    <div className='pl-8 md:ml-8'>KC Knowledge Center</div>
                    <div className=' grid md:grid-cols-3 items-center gap-4 mb-12'>
                        {/* <div className='col-span-1 text-center'>
                            <p className='text-lg font-semibold'>Enter the field</p>
                            <p className='text-xs'>Subscribe to our newsletter</p>
                        </div> */}
                        {/* <div className='col-span-1 text-center'>
                            <div className='grid grid-cols-3 md:grid-cols-1'>
                                <div></div>
                                <div className='flex '>
                                    <div><TextField className='w-48 h-18 bg-light' id="outlined-basic" label="Email" variant="outlined" /></div>
                                    <div><button className='bg-blue-500 rounded-none h-18 p-4'>Go</button></div>
                                </div>
                                <div></div>
                            </div>
                        </div> */}

                    </div>

                </div>

                <div className='p-8 grid md:grid-cols-4'>
                    <div className='col-span-1 text-center'>
                        <div className='text-primary '><Link to={LANDINGABOUT}>About us</Link></div>
                        <div className='h-[1px] mt-2 mb-4 w-[60%] bg-gray-300 m-auto'></div>
                        <div className='text-base font-extralight mt-3'><Link to={LANDINGABOUT}> Our Story</Link></div>
                        <div className='text-base font-extralight mt-3'><Link to={""}> Careers</Link></div>
                        <div className='text-base font-extralight mt-3'><Link to={LANDINGABOUT}> Why KC</Link></div>
                        {/* <div className='text-base font-extralight mt-3'><Link to={""}> Lifestyle</Link></div> */}
                    </div>

                    <div className='col-span-1 text-center'>
                        <div className='text-primary '>Academics</div>
                        <div className='h-[1px] mt-2 mb-4 w-[60%] bg-gray-300 m-auto'></div>
                        <div className='text-base font-extralight mt-3'> <Link to={STUDENTRESOURCES}>Resources</Link></div>
                        <div className='text-base font-extralight mt-3'> <Link to={LANDINGSTEMCOMP}>Competitions</Link></div>
                        <div className='text-base font-extralight mt-3'> <Link to={LANDINGBLOG}>Blog</Link></div>
                        {/* <div className='text-base font-extralight mt-3'> <Link to={""}>Contacts</Link></div> */}
                    </div>

                    <div className='col-span-1 text-center'>
                        <div className='text-primary'>Resources</div>
                        <div className='h-[1px] mt-2 mb-4 w-[60%] bg-gray-300 m-auto'></div>
                        <div className='text-base font-extralight mt-3'> <Link to={""}>FaQs</Link></div>
                        <div className='text-base font-extralight mt-3'> <Link to={STUDENTNOTIFICATIONS}>Notifications</Link></div>
                        <div className='text-base font-extralight mt-3'> <Link to={""}>Guides</Link></div>
                        {/* <div className='text-base font-extralight mt-3'> <Link to={""}>Accessibility</Link></div> */}
                    </div>

                    <div className='col-span-1 text-center'>
                        <div className='text-primary'>Administrative Office</div>
                        <div className='h-[1px] mt-2 mb-4 w-[60%] bg-gray-300 m-auto'></div>
                        <div className='text-base font-extralight mt-3'> <Link to={""}>CUIB Buea, SW</Link></div>
                        <div className='text-base font-extralight mt-3'> <a target='_blank' href={"mailto:knowledgecenterbuea@gmail.com"}>knowledgecenterbuea@gmail.com</a></div>
                        <div className='text-base font-extralight mt-3'> <a target='_blank' href={"mailto:Kcstemhub@gmail.com"}>Kcstemhub@gmail.com</a></div>
                        <div className='text-base font-extralight mt-3'> <a target='_blank' href={"call:237680789894"}>Tel: 680789894</a></div>
                    </div>
                </div>

                <div>
                    <div className='pb-5'>
                        <div className='border-[1px] md:w-[95%] bg-light m-auto my-8'></div>
                        <div className='grid md:grid-cols-3 pl-8 font-light text-sm'>
                            <div>
                                @ 2022 Knowledge Center Edition, Buea
                            </div>
                            <div className='my-3'>
                                <div className='flex gap-3'>
                                    <a target='_blank' href="https://www.facebook.com/KnowledgeCenterBuea"><SiFacebook className='mr-3 text-[20px]' /></a>
                                    <FaTwitterSquare className='mr-3 text-[20px]' />
                                    <AiOutlineInstagram className='mr-3 text-[20px]' />
                                    <FiMail className='mr-3 text-[20px]' />
                                    <FaGooglePlusSquare className='mr-3 text-[20px]' />
                                </div>
                            </div>
                            <div className='flex gap-8 font-light text-sm'>
                                <div>Privacy Policy</div>
                                <div>Terms of Use</div>
                            </div>

                        </div>

                    </div>
                    {/* <div className='border-2 w-[90%] bg-light mt-5'></div> */}
                    <div></div>
                </div>


            </div>
        </>
    )
}

export default LandingFooter