import { Alert, Avatar, Button, CircularProgress, InputLabel, Link, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiBrain, BiHome, BiPhone } from 'react-icons/bi'
import { FaSchool } from 'react-icons/fa'
import { GiLifeInTheBalance } from 'react-icons/gi'
import { selectStudent } from '../../../api/states/student.state'
import { STUDENTLOGIN } from '../../../constants/routes.constant'
import { defaultClasses, defaultStudent } from '../../../data/default.data'
import { useDispatch, useSelector } from 'react-redux'
import { updateStudent } from '../../../api/states/student.state'
import { createStudentAccount, editStudentInfo } from '../../../api/firebase/auth.api'
import axios from 'axios'

const Profile: React.FC = ({ }) => {

    const student = useSelector(selectStudent)
    const [editableStudent, setEditableStudent] = useState(defaultStudent)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [editing, setEditing] = useState(false)
    const dispatch = useDispatch()





    async function onSubmit(e: any) {
        e.preventDefault()
        console.log(student)
        window.scrollTo(0, 0)
        setLoading(true)
        setError("")
        setSuccess('')

        try {

            const result = await editStudentInfo(editableStudent)
            console.log(result)
            setSuccess("successfully edited your profile")
            dispatch(updateStudent(editableStudent))
            setEditing(false)

        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
        setLoading(false)
    }
    function scroll() {

    }

    useEffect(() => {

        if (student) {
            setEditableStudent(student)
        }

        return () => {

        }
    }, [student])


    return (
        <div className='p-4'>
            <div className="grid md:grid-cols-4 border-b">
                <div className="md:col-span-1 py-6 md:py-20">

                    <Avatar className='m-auto' sx={{ background: "#3b75cb", width: "80px", height: "80px", fontSize: "40px" }}>
                        <span className="font-light">{student?.name[0]}</span>
                    </Avatar>
                </div>
                <div className="col-span-3 py-4">
                    <div className="text-2xl">{student?.name}</div>
                    <div className='text-lg text-gray-500 mb-8'>{student?.email}</div>
                    <div className=""><BiPhone className='inline-block mr-1' /> {student?.phone}</div>
                    <div className=""><BiHome className='inline-block mr-1' /> {student?.city}</div>
                    <div className=""><FaSchool className='inline-block mr-1' /> {student?.school}</div>
                    <div className=""><BiBrain className='inline-block mr-1' /> {student?.form}</div>
                    <div className="py-6">
                        {!editing && <Button onClick={() => {
                            setEditing(true);
                            setTimeout(() => {
                                window.scroll(10000, 10000)
                            }, 1000);
                        }} variant="outlined" sx={{ marginRight: "10px" }} >Edit</Button>}
                        {/* <Button variant="contained">SAVE</Button> */}
                    </div>
                </div>
                <div>

                </div>
            </div>
            {editing && <div className='grid lg:grid-cols-3 '>
                <div className='h-0 md:h-auto'>

                </div>
                <div className='col-span-1'>
                    {success && <Alert severity='info'>{success}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                    <form onSubmit={onSubmit} action="">
                        <div className='mx-10 my-14 font-light text-3xl '>Edit Information {loading && <CircularProgress />}</div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={editableStudent.name} onChange={(e) => setEditableStudent({ ...editableStudent, name: e.target.value })} className='w-full' id="outlined-basic" label="Names" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={editableStudent.age} onChange={(e) => setEditableStudent({ ...editableStudent, age: e.target.value })} className='w-full' id="outlined-basic" label="Age" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <InputLabel className='mb-2'>Form / Class</InputLabel>
                            <Select
                                required
                                className='w-full'
                                label="Level"
                                value={editableStudent.form} onChange={(e) => setEditableStudent({ ...editableStudent, form: (e.target.value) })}
                            >
                                {
                                    defaultClasses.map((form, index) => {
                                        return (
                                            <MenuItem value={form} key={index} className='capitalize'>{form}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </div>

                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={editableStudent.school} onChange={(e) => setEditableStudent({ ...editableStudent, school: e.target.value })} className='w-full' id="outlined-basic" label="School" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={editableStudent.city} onChange={(e) => setEditableStudent({ ...editableStudent, city: e.target.value })} className='w-full' id="outlined-basic" label="Town or City" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={editableStudent.phone} onChange={(e) => setEditableStudent({ ...editableStudent, phone: e.target.value })} className='w-full' id="outlined-basic" label="Phone Number" variant="outlined" />
                        </div>
                        <div className={loading ? "opacity-50" : "opacity-100"}>
                            <button disabled={loading} type='submit' className='button rounded-md my-14 w-full'>Save</button>
                        </div>
                    </form>
                    <div className="h-10"></div>

                </div>
                <div></div>
            </div>
            }
        </div>
    )
}

export default Profile