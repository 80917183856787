import React, { useState } from 'react'
import AdminHeader from '../Header/AdminHeader.component'
import Header from '../Header/AdminHeader.component'
import Menu from '../Menu/Menu.component'

const PrimaryLayout: React.FC<{ Page: React.FC<{}> }> = ({ Page }) => {

    const [showMenu, setShowMenu] = useState(false)

    function toggleMenu() {
        setShowMenu(!showMenu)
    }

    return (
        <div className='grid lg:grid-cols-8'>
            <div className=' lg:col-span-2 h-0 z-10 lg:h-full'>
                {<Menu toggleMenu={toggleMenu} />}
            </div>
            <div className='bg-light col-span-8 z-30 lg:col-span-6 relative '>
                <AdminHeader toggleMenu={toggleMenu} />
                <Page />
                {showMenu && <div onClick={toggleMenu} className='fixed top-0 z-40 bg-dark bg-opacity-0 h-full w-full'>
                    <div className="relative">
                        <Menu toggleMenu={toggleMenu} />
                    </div>
                </div>}
            </div>
        </div>

    )
}

export default PrimaryLayout

