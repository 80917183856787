import React from 'react'

import PrimaryLayout from '../PrimaryLayout/PrimaryLayout.component'
import { BiSearch } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import flynn from '../../assets/images/RomeFlynn.jpeg'
import { useSelector } from 'react-redux'
import { selectAdmin } from '../../api/states/admin.state'
import { Avatar } from '@mui/material'

const AdminHeader: React.FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
    const admin = useSelector(selectAdmin)
    return (
        <div className='grid grid-cols-3 p-2 sticky top-0 z-40  pb-3 bg-white shadow-xl shadow-gray-200' >
            <div className='col-span-2 md:col-span-2'>
                <GiHamburgerMenu onClick={toggleMenu} className='float-left mt-1 mr-2 inline-block lg:hidden' />
                <div className='font-bold'>Welcome, Beltus</div>
                <div className='text-gray2 w-0 sm:w-auto hidden sm:block'>Here's what's happening with KC today</div>
            </div>
            <div className='col-span-1 md:col-span-1 text-center'>
                {/* <div className="p-2 border bg-light w-0 sm:w-auto hidden sm:inline-block rounded-md mt-2 relative">
                    <BiSearch className='absolute left-3 top-3 text-gray1' />
                    <input type="text" className='pl-6 outline-none bg-transparent' placeholder='Search...' />
                    <button className="bg-white absolute right-2 rounded shadow-sm p-[2px] px-3 top-[7px]">
                        Go
                    </button>
                </div> */}
                {admin && <Avatar className='float-right mt-1 mr-1 inline-block ' sx={{ background: "#3b75cb" }}> {admin.name.length > 0 && admin.name[0]}</Avatar>}
            </div>
        </div>
    )
}

export default AdminHeader