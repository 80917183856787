import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { selectCompetition } from '../../../api/states/competitions.state'
import { ADMINADDCOMPETITION, COMPETITIONDETAIL } from '../../../constants/routes.constant'

const CompetitionAdmin = () => {

    const competitions = useSelector(selectCompetition)
    const navigate = useNavigate()

    return (
        <div className='p-5 min-h-screen overflow-hidden'>
            <div className='relative py-6'>
                <div className='bg-white w-32 flex float-right p-2 rounded-md'>
                    <span className='pr-3 mt-1'><AiOutlinePlus /></span>
                    <Link to={ADMINADDCOMPETITION}>Add New</Link>
                </div>
            </div>
            <div className="w-[100wh] overflow-x-hidden">
                <div className='mt-10 pb-10 bg-white w-[200vw]  md:w-full px-7 rounded-md '>
                    <h3 className='py-3'>All Competitions</h3>
                    <div className='mt-3 grid grid-cols-8 font-semibold'>
                        <div className='col-span-1'>Name</div>
                        <div className='col-span-2'>A/L streams</div>
                        <div className='col-span-1'>O/L Streams</div>
                        <div className='col-span-1'>on going</div>
                        <div className='col-span-2'>Date</div>
                        {/* <div className='col-span-1'>Status</div> */}
                    </div>
                    {
                        competitions.map((competition, index) => {
                            return (
                                <div onClick={() => navigate(COMPETITIONDETAIL, { state: { competition } })} className='mt-3 grid grid-cols-8 hover:bg-gray-200 p-1 rounded-md cursor-pointer '>
                                    <div className='col-span-1'>{competition.name}</div>
                                    <div className='col-span-2'>{competition.levels[0].streams.map((strm) => strm.name + ", ")}</div>
                                    <div className='col-span-1'>{competition.levels[1].streams.map((strm) => strm.name + ", ")}</div>
                                    <div className='col-span-1'>{competition.accepting ? "YES" : "NO"}</div>
                                    <div className='col-span-2'>{competition.timestamp}</div>
                                    {/* <div className='col-span-1'>Status</div> */}
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )

}

export default CompetitionAdmin