import { Accordion, AccordionSummary, Typography, AccordionDetails, Button, LinearProgress } from '@mui/material'
import { channel } from 'diagnostics_channel'
import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { getMessagesForChannel } from '../../api/firebase/notification.api'
import { Student } from '../../interfaces/auth.interface'
import { Message } from '../../interfaces/notification.interface'

const MessageChannelCard: React.FC<{ channel: string, student?: Student }> = ({ channel, student }) => {

    const [messages, setMessages] = useState<Message[]>([])
    const [loading, setLoading] = useState(false)


    function getMessages() {
        if (messages.length <= 0) {
            setLoading(true)
            getMessagesForChannel(channel, (messages) => {
                setMessages(messages)
                setLoading(false)
            })
        }
    }


    return (
        <Accordion onClick={() => getMessages()} key={channel}>
            <AccordionSummary
                expandIcon={<BiChevronDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{student ? student.name : channel}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {loading && <LinearProgress />}
                {
                    messages.map((message, index) => {
                        return (
                            <div className={`p-2 ${index % 2 ? "bg-gray-100" : "bg-white"}`}>
                                <span className="font-bold">{message.title} </span>
                                <span className="ml-4 text-sm truncate">{message.description}</span>
                                <span className="ml-4 text-sm float-right ">{new Date(message.timestamp).toLocaleDateString()}</span>
                            </div>
                        )
                    })
                }

            </AccordionDetails>
        </Accordion>
    )
}

export default MessageChannelCard