import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import { selectAdmin } from '../../api/states/admin.state';
import { selectStudent } from '../../api/states/student.state';
import { ABOUT, ADMINADDBLOG, ADMINADDCOMPETITION, ADMINBLOG, ADMINEDITBLOG, ADMINEDITRESOURCE, ADMINNCOMPOSENOTIF, ADMINNOTIFICATIONS, ADMINPROFILE, ADMINRESOURCES, ADMINSTAFFS, ADMINSTUDENTDETAIL, ADMINSTUDENTS, COMPETITIONADMIN, COMPETITIONDETAIL, LANDING, LANDINGABOUT, LANDINGBANKDETAILS, LANDINGBLOG, LANDINGBLOGDETAIL, LANDINGDONATION, LANDINGSTEMCOMP, STATISTICS, STUDENTLOGIN, STUDENTNOTIFICATIONDETAIL, STUDENTNOTIFICATIONS, STUDENTPROFILE, STUDENTREGISTRATION, STUDENTRESOURCES, STUDENTSIGNUP } from '../../constants/routes.constant';
import About from '../../screens/Admin/About/About.screen';
import AdminComposeNotifications from '../../screens/Admin/AdminNotifications/AdminComposeNotifications.screen';
import AdminNotification from '../../screens/Admin/AdminNotifications/AdminNotification.screen';
import AdminStaffs from '../../screens/Admin/AdminStaffs/AdminStaffs.screen';
import AdminAddBlog from '../../screens/Admin/BlogAdmin/AdminAddBlog.screen';
import AdminEditBlog from '../../screens/Admin/BlogAdmin/AdminEditBlog.screen';
import BlogAdmin from '../../screens/Admin/BlogAdmin/BlogAdmin.screen';
import AddCompetionAdmin from '../../screens/Admin/CompetitionAdmin/AddCompeitionAdmin.screen';
import CompetitionAdmin from '../../screens/Admin/CompetitionAdmin/CompetitionAdmin.screen';
import CompetitionDetail from '../../screens/Admin/CompetitionAdmin/CompetitionDetail.screen';
import Landing from '../../screens/Admin/Landing/Landing.screen';
import ProfileAdmin from '../../screens/Admin/ProfileAdmin/ProfileAdmin.screen';
import EditResourceAdmin from '../../screens/Admin/ResourcesAdmin/EditResourceAdmin.screen';
import ResourcesAdmin from '../../screens/Admin/ResourcesAdmin/ResourcesAdmin.screen';
import StudentDetail from '../../screens/Admin/StudentsAdmin/StudentDetail.screen';
import StudentsAdmin from '../../screens/Admin/StudentsAdmin/StudentsAdmin.screen';
import LandingAbout from '../../screens/Landing/About/About.screen';
import Blog from '../../screens/Landing/Blog/Blog.screen';
import BlogDetail from '../../screens/Landing/Blog/BlogDetail.screen';
import BankDetails from '../../screens/Landing/Donations/BankDetails.screen';
import Donation from '../../screens/Landing/Donations/Donations.screen';
import MainLandingPage from '../../screens/Landing/MainLandingPage/MainLandingPage.screen';
import StemCompetitionInfo from '../../screens/Landing/Stem/StemCompetitionInfo.screen';
import Login from '../../screens/Students/Login/Login.screen';
import NotificationDetail from '../../screens/Students/Notifications/NotificationDetail.screen';
import Notifications from '../../screens/Students/Notifications/Notifications.screen';
import Profile from '../../screens/Students/Profile/Profile.screen';
import Registration from '../../screens/Students/Registration/Registration.screen';
import Resources from '../../screens/Students/Resources/Resources.screen';
import Signup from '../../screens/Students/Signup/Signup.screen';
import CompetitionDetailLayout from '../Competition/CompetitionDetailLayout.component';
import LandingLayout from '../LandingLayout/LandingLayout.component';
import PrimaryLayout from '../PrimaryLayout/PrimaryLayout.component';
import StudentLayout from '../StudentLayout/StudentLayout.component';




const Navigation = () => {

    const student = useSelector(selectStudent)
    const admin = useSelector(selectAdmin)

    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {

        // if (student) {
        //     navigate(STUDENTREGISTRATION)
        //     return;
        // }
        if (!admin && location.pathname.match("admin")) {
            navigate(STUDENTLOGIN)
            return;

        }
    }, [student, admin, location])

    useEffect(() => {
        window.scroll(0, 0)
    }, [location])

    return (
        <div>
            <Routes>
                <Route path='/' element={<StudentLayout Page={MainLandingPage} />} />
                <Route path='/home' element={<StudentLayout Page={MainLandingPage} />} />
                <Route path='/admin' element={<PrimaryLayout Page={Landing} />} />
                <Route path={STATISTICS} element={<PrimaryLayout Page={Landing} />} />
                <Route path={COMPETITIONADMIN} element={<PrimaryLayout Page={CompetitionAdmin} />} />
                <Route path={COMPETITIONDETAIL} element={<CompetitionDetailLayout />} />


                <Route path={ADMINPROFILE} element={<PrimaryLayout Page={ProfileAdmin} />} />
                <Route path={ADMINBLOG} element={<PrimaryLayout Page={BlogAdmin} />} />
                <Route path={ADMINRESOURCES} element={<PrimaryLayout Page={ResourcesAdmin} />} />
                <Route path={ADMINADDCOMPETITION} element={<PrimaryLayout Page={AddCompetionAdmin} />} />
                <Route path={ADMINSTUDENTDETAIL} element={<PrimaryLayout Page={StudentDetail} />} />
                <Route path={ADMINSTUDENTS} element={<PrimaryLayout Page={StudentsAdmin} />} />
                <Route path={ADMINADDBLOG} element={<PrimaryLayout Page={AdminAddBlog} />} />
                <Route path={ADMINEDITBLOG} element={<PrimaryLayout Page={AdminEditBlog} />} />
                <Route path={ADMINEDITRESOURCE} element={<PrimaryLayout Page={EditResourceAdmin} />} />
                <Route path={ADMINNOTIFICATIONS} element={<PrimaryLayout Page={AdminNotification} />} />
                <Route path={ADMINNCOMPOSENOTIF} element={<PrimaryLayout Page={AdminComposeNotifications} />} />
                <Route path={ADMINSTAFFS} element={<PrimaryLayout Page={AdminStaffs} />} />
                <Route path={ABOUT} element={<About />} />


                <Route path={LANDINGABOUT} element={<StudentLayout Page={LandingAbout} />} />
                <Route path={LANDINGSTEMCOMP} element={<StudentLayout Page={StemCompetitionInfo} />} />
                <Route path={LANDINGBLOG} element={<StudentLayout Page={Blog} />} />
                <Route path={LANDINGBLOGDETAIL} element={<StudentLayout Page={BlogDetail} />} />
                <Route path={LANDINGDONATION} element={<StudentLayout Page={Donation} />} />
                <Route path={LANDINGBANKDETAILS} element={<StudentLayout Page={BankDetails} />} />


                <Route path={STUDENTLOGIN} element={<StudentLayout Page={Login} />} />
                <Route path={STUDENTSIGNUP} element={<StudentLayout Page={Signup} />} />
                <Route path={STUDENTREGISTRATION} element={<StudentLayout Page={Registration} />} />
                <Route path={STUDENTNOTIFICATIONS} element={<StudentLayout Page={Notifications} />} />
                <Route path={STUDENTPROFILE} element={<StudentLayout Page={Profile} />} />
                <Route path={STUDENTRESOURCES} element={<StudentLayout Page={Resources} />} />
                <Route path={STUDENTNOTIFICATIONDETAIL} element={<StudentLayout Page={NotificationDetail} />} />


            </Routes>
        </div>
    )
}

export default Navigation