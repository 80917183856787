import { async } from "@firebase/util";
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limitToLast, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { Student, User } from "../../interfaces/auth.interface";
import { BlogPost } from "../../interfaces/blog.interface";
import { CompetitionApplication } from "../../interfaces/competition.interface";
import * as uuid from "uuid";


export async function fetchTopBlogPost(student?: Student) {
    const blogPostRef = query(collection(getFirestore(), 'blog'), orderBy('timestamp', 'asc'), limitToLast(10))

    return getDocs(blogPostRef).then((res) => {
        return res.docs.map(doc => doc.data() as BlogPost)
    })

}




export async function createBlogPost(post: BlogPost): Promise<void> {
    const blogPostRef = doc(getFirestore(), 'blog', post.id);
    return setDoc(blogPostRef, post);

}

export async function deleteBlogPost(post: BlogPost) {
    const blogPostRef = doc(getFirestore(), 'blog', post.id);
    return deleteDoc(blogPostRef);
}



export async function checkIfPostWithNameExists(post: BlogPost): Promise<boolean> {
    const weatherPostRef = query(collection(getFirestore(), 'blog'), where("title", "==", post.title), limitToLast(1));
    return getDocs(weatherPostRef).then((res) => {
        return res.docs.length > 0;
    })
}


export async function fetchBlogById(blogId: string): Promise<BlogPost> {
    const blogRef = doc(getFirestore(), 'blog', blogId);
    return getDoc(blogRef).then((res) => {
        return res.data() as BlogPost;
    })
}

export async function onfetchBlogById(blogId: string, onSuccess: (post: BlogPost) => void) {
    const blogRef = doc(getFirestore(), 'blog', blogId);
    onSnapshot(blogRef, res => {
        onSuccess(res.data() as BlogPost);
    })
}

export async function getBlogPostById(id: string) {
    const blogPostRef = doc(getFirestore(), 'blog', id);

    return getDoc(blogPostRef).then((doc) => {
        return (doc.data() as BlogPost)
    })
}

export async function likePost(postId: string, user: Student) {
    let post = await getBlogPostById(postId)

    if (!post) {
        const error = new Error()
        error.message = 'Post with id "' + postId + '" does not exist'
        throw error;
    }
    post.reaction = post.reaction || { likes: [], dislikes: [], comments: [] }

    const matchLike = (post.reaction).likes.find(like => like.user.email == user.email)

    if (matchLike) {
        post.reaction.likes = post.reaction.likes.filter(like => like.user.email != user.email)
    } else {
        post.reaction.likes.push({ user: user, timestamp: Date.now() })
    }

    const blogRef = doc(getFirestore(), 'blog', postId)
    return updateDoc(blogRef, { reaction: post.reaction })

}

export async function dislikePost(postId: string, user: Student) {
    let post = await getBlogPostById(postId)

    if (!post) {
        const error = new Error()
        error.message = 'Post with id "' + postId + '" does not exist'
        throw error;
    }

    post.reaction = post.reaction || { likes: [], dislikes: [], comments: [] }

    const matchDislike = post.reaction.dislikes.find(dislike => dislike.user.email == user.email)
    if (matchDislike) {
        post.reaction.dislikes = post.reaction.dislikes.filter(dislike => dislike.user.email != user.email)
    } else {
        post.reaction.dislikes.push({ user: user, timestamp: Date.now() })
    }

    const blogRef = doc(getFirestore(), 'blog', postId)
    return updateDoc(blogRef, { reaction: post.reaction })

}

export async function commentOnPost(postId: string, user: Student, message: string) {
    let post = await getBlogPostById(postId)

    if (!post) {
        const error = new Error()
        error.message = 'Post with id "' + postId + '" does not exist'
        throw error;
    }

    post.reaction = post.reaction || { likes: [], dislikes: [], comments: [] }
    post.reaction.comments.push({ id: uuid.v4(), message, user, timestamp: Date.now() })


    const blogRef = doc(getFirestore(), 'blog', postId)
    return updateDoc(blogRef, { reaction: post.reaction })

}