import { Alert, Chip, CircularProgress, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react'
import { GiCancel } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { createBlogPost } from '../../../api/firebase/blog.api';
import { selectAdmin } from '../../../api/states/admin.state';
import { selectStudents } from '../../../api/states/students.state';
import { convertNameToPostId } from '../../../api/utilities/blog.utility';
import { CKEditor, ClassicEditor } from '../../../components/BlogAdmin/CKEditor.component';
import { defaultChannels, defaultClasses } from '../../../data/default.data';
import { Student } from '../../../interfaces/auth.interface';
import { BlogPost } from '../../../interfaces/blog.interface'
import { Message } from '../../../interfaces/notification.interface';
import * as uuid from 'uuid'
import { sendPrivateMessage, sendPublicMessage } from '../../../api/firebase/notification.api';


const AdminComposeNotifications = () => {
    const [content, setContent] = useState(" <i> Start Message <br/><br/><br/><br/><br/></i > ")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [selectedClasses, setSelectedClasses] = useState<string[]>([])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const admin = useSelector(selectAdmin)
    const [selectedChannel, setSelectedChannel] = useState<"PUBLIC" | "PRIVATE">("PUBLIC")
    const [selectedStudents, setSelectedStudents] = useState<string[]>([])
    const students = useSelector(selectStudents)



    async function sendMessage() {
        if (!admin) {
            setError("Login first to send message")
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')

        if (!title) {
            setError("Please provide a title for this message");
            return false
        }
        if (!description) {
            setError("Please provide a description for this message");
            return false
        }
        if (!content) {
            setError("Please provide a content for this message");
            return false
        }


        setLoading(true)

        let notification: Message = {
            channel: selectedChannel,
            sender: admin,
            message: content,
            timestamp: Date.now(),
            title: title,
            description: description,
            forms: selectedClasses,
            id: uuid.v4()
        }

        if (selectedChannel == "PRIVATE") {
            notification.receivers = selectedStudents;
            try {

                await sendPrivateMessage(notification)
                setSuccess("Successfully sent message")

            } catch (err: any) {
                setError(err?.message)
            }
        } else {
            try {

                await sendPublicMessage(notification)
                setSuccess("Successfully sent Message")

            } catch (err: any) {
                setError(err?.message)
            }
        }


        setLoading(false)




    }


    return (
        <div className='p-3'>
            <div className="grid grid-cols-5">
                <div></div>
                <div className='col-span-3 py-8'>
                    <div className="p-2">
                        {success && <Alert severity='info'>{success}</Alert>}
                        {error && <Alert severity='error'>{error}</Alert>}
                    </div>
                    <div className='mx-10 my-6 font-light text-3xl '>Create New Message  {loading && <CircularProgress />}</div>

                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Title' className='p-2 rounded border outline-blue-300 w-full mb-5' />
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' className='p-2 rounded border outline-blue-300 w-full mb-5' />

                    <div className='mb-5'>
                        <div className="mb-2 text-sm">Channel</div>
                        <Select className='bg-white' fullWidth value={selectedChannel} onChange={(e) => setSelectedChannel(e.target.value as "PRIVATE")}>
                            {
                                defaultChannels.map((channel, index) => (<MenuItem value={channel} className='capitalize'>{channel.toLowerCase()}</MenuItem>))
                            }
                        </Select>
                    </div>
                    <div className='mb-5'>
                        <div className="mb-2 text-sm">Select Receiver(s)</div>
                        <div>
                            {
                                selectedChannel == "PRIVATE" && <Select multiple value={selectedStudents} onChange={(e) => setSelectedStudents(e.target.value as string[])} fullWidth className='bg-white'>

                                    {students.map((student, index) => (<MenuItem value={student.email} className='capitalize'>{student.name} <small className='text-blue-600'> ({student.email})</small></MenuItem>))}

                                </Select>
                            }
                        </div>
                        <div>
                            {
                                selectedChannel == "PUBLIC" && <Select multiple value={selectedClasses} onChange={(e) => setSelectedClasses(e.target.value as string[])} fullWidth className='bg-white' >

                                    {defaultClasses.map((form, index) => (<MenuItem value={form} >{form}</MenuItem>))}

                                </Select>
                            }
                        </div>
                    </div>


                    <div className="editor">
                        <CKEditor

                            editor={ClassicEditor}
                            data={content}
                            onReady={(editor: any) => {
                                // You can store the "(:any)" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setContent(data)
                            }}
                            className='min-h-[60vh]'

                        />
                    </div>
                    <div className="p-8" onClick={sendMessage}>
                        <button className="rounded p-2 text-white float-right px-5 bg-danger">Save</button>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default AdminComposeNotifications