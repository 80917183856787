import { Competition, CompetitionApplication, Donation } from "../../interfaces/competition.interface";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { Student } from "../../interfaces/auth.interface";
import { auth } from "."


const competitionRef = (compId: string) => doc(getFirestore(), 'competitions/' + compId)
const prospectRef = (compId: string, partId: string,) => doc(getFirestore(), `competitions/${compId}/prospects/${partId}`)
const allProspectsRef = (compId: string) => collection(getFirestore(), `competitions/${compId}/prospects`)
const allParticipantsRef = (compId: string) => collection(getFirestore(), `competitions/${compId}/registered`)





export async function registerForCompetitition(application: CompetitionApplication) {

    const appls = await getAllParticipants(application);

    const student = appls.find((app) => app.student.email == application.student.email);
    if (student) {
        const err = new Error();
        err.message = "You have already registered for the competition"
        throw err;
    }

    return setDoc(prospectRef(application.name, application.student.email), application)
}


export async function getAllProspects(competition: Competition | CompetitionApplication) {

    return getDocs(allProspectsRef(competition.name)).then((res => res.docs.map(doc => doc.data() as CompetitionApplication)))

}

export async function getAllParticipants(competition: Competition | CompetitionApplication) {

    return getDocs(allParticipantsRef(competition.name)).then((res => res.docs.map(doc => doc.data() as CompetitionApplication)))

}

export async function getAllRegistered(competition: Competition | CompetitionApplication) {
    const parts = await getAllParticipants(competition)
    const pros = await getAllProspects(competition)

    return [...parts, ...pros]


}


export async function getLatestCompetion(): Promise<Competition | null> {
    const q = query(collection(getFirestore(), 'competitions'), orderBy("timestamp", "desc"), limit(1));
    return getDocs(q).then((res) => {
        const result = res.docs.map(doc => doc.data() as Competition)
        if (result.length > 0) {
            return result[0]
        }
        return null
    })

}


/**
 * Creates a new competition 
 * 
 * @param {Competition} competition - Competition object 
 * 
 * @returns {Promise<Competition>} - a promise resolved with the new competition  
 */
export async function createCompetition(competition: Competition): Promise<Competition> {

    const exists = await verifyCompetitionExists(competition)
    if (exists) {
        const error = new Error()
        error.message = "a competition with the same competition name already exists"
        throw error;
    }
    const competitionRef = doc(getFirestore(), 'competitions', competition.name)
    await setDoc(competitionRef, competition)
    return competition

}



/**
 * edits an existing competition 
 * 
 * @param {Competition} competition - Competition object 
 * 
 * @returns {Promise<Competition>} - a promise resolved with the new competition  
 */
export async function editCompetition(competition: Competition): Promise<Competition> {

    const exists = await verifyCompetitionExists(competition)
    if (!exists) {
        const error = new Error()
        error.message = "The competition you are trying to edit does not exists"
        throw error;
    }
    const competitionRef = doc(getFirestore(), 'competitions', competition.name)
    await setDoc(competitionRef, competition)
    return competition

}


/**
 * Verifies if competition with competition name already exists
 * 
 * @param {Competition} -  competition object to be verified
 * 
 * @returns {Promise<boolean>} - a promise resolved with true if comparison is true, else false
 */


export async function verifyCompetitionExists(competition: Competition): Promise<boolean> {

    const q = query(collection(getFirestore(), 'competitions'), where('name', '==', competition.name));
    const res = await getDocs(q);
    return res.docs.length > 0 ? true : false;
}

/**
 * Gets all competitions ever submitted to the database
 * 
 * @param callBack - called everytime a competion is created, deleted or edited
 */
export async function getAllCompetitions(callBack: (competitions: Competition[]) => void) {

    const q = query(collection(getFirestore(), 'competitions'), orderBy("timestamp", "desc"));
    onSnapshot(q, (res) => {
        const competitions = res.docs.map(doc => doc.data() as Competition)
        console.log(competitions)
        callBack(competitions)
    });

}


/**
 * 
 *  
 */

export async function donateForCompetition(donor: Donation) {

    donor.paid = false;

    if (donor.amount < 100) {
        const error = new Error()
        error.message = " You cannot donate less thant 100 FCFA";
        throw error;
    }

    const donationsRef = doc(getFirestore(), 'donations', donor.ref!)
    return setDoc((donationsRef), donor);
}


export async function getAllDonations(callBack: (donations: Donation[]) => void) {
    const donationsRef = collection(getFirestore(), 'donations')
    onSnapshot(donationsRef, (res) => {
        callBack([...res.docs.map(doc => doc.data() as Donation)])
    })
}