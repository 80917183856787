import { configureStore } from '@reduxjs/toolkit'
import studentReducer from './student.state'
import studentNotificationsReducer from './studentNotifications.state'
import competitionsReducer from './competitions.state'
import adminReducer from './admin.state'
import studentsReducer from './students.state'
// ...

export const store = configureStore({
    reducer: {
        student: studentReducer,
        studentNotifications: studentNotificationsReducer,
        competitions: competitionsReducer,
        admin: adminReducer,
        students: studentsReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch