import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchTopBlogPost } from '../../api/firebase/blog.api'
import { ADMINEDITBLOG } from '../../constants/routes.constant'
import { BlogPost } from '../../interfaces/blog.interface'

const AdminBlogCategories: React.FC = () => {

    const [blogPosts, setBlogPosts] = useState<BlogPost[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        getTopPosts()
    }, [])

    async function getTopPosts() {
        try {
            const posts = await fetchTopBlogPost()
            setBlogPosts([...posts])
        } catch (err) {

        }
    }



    return (
        <div className="p-2 relative h-full"
        >
            <h3 className="text-2xl my-3 mb-7">Top Posts</h3>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-8 mb-20">
                {
                    blogPosts.map((obj) => {
                        return (
                            <div onClick={() => { navigate(ADMINEDITBLOG, { state: { blog: obj } }) }} className='flex p-2 bg-white'>
                                <div>
                                    <img src={obj.image} alt="" className='w-[200px] h-full object-cover' />
                                </div>
                                <div className='px-4'> <div className="text-xl my-2">{obj.title}</div>
                                    <div>
                                        {obj.description}
                                    </div>
                                    <div className='text-sm text-gray-600 my-2'>
                                        {new Date(obj.timestamp).toDateString()}
                                    </div></div>
                            </div>
                        )
                    })
                }
            </div>

        </div >
    )
}

export default AdminBlogCategories


const blogObject = [
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    },
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    },
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    },
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    },
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    },
    {
        title: 'JavaScript',
        description: 'JavaScript is a JavaScript framework for managing and publishing web applications.',
        slug: 'javascript',
        image: 'https://divineyouwellness.com/blog/wp-content/uploads/2021/03/shutterstock_563564683-scaled.jpg',
        timestamp: Date.now()

    }
]