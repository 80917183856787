import React, { useEffect, useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import { generateStreamDetail } from '../../api/utilities/streams.utility'
import { Stream } from '../../interfaces/competition.interface'

const StreamsInputItem: React.FC<{ onStreamChange: (stream: Stream) => void, stream: Stream }> = ({ stream, onStreamChange }) => {

    const [thisStream, setThisStream] = useState(stream)

    function handleChange(value: string) {
        const str = generateStreamDetail(value);
        setThisStream(str)
        onStreamChange(str)
    }

    useEffect(() => {
        if (stream) {
            setThisStream(stream)
        }
    }, [stream])


    return (
        < >

            <input type="text" value={thisStream.name} onChange={(e) => handleChange(e.target.value || "")} className='w-100  outline-primary border rounded px-2 py-1' />

        </>
    )
}

export default StreamsInputItem