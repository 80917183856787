import { Alert, Checkbox, CircularProgress, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { info } from 'console'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { createStudentAccount } from '../../../api/firebase/auth.api'
import bgImage from '../../../assets/images/student.jpeg'
import { STUDENTLOGIN, STUDENTSIGNUP } from '../../../constants/routes.constant'
import { defaultClasses, defaultCompApplication, defaultCompetition, defaultStudent } from '../../../data/default.data'
import { useDispatch, useSelector } from 'react-redux'
import { selectStudent, updateStudent } from '../../../api/states/student.state'
import { Competition, CompetitionApplication, Stream, Subject } from '../../../interfaces/competition.interface'
import { registerForCompetitition } from '../../../api/firebase/competition.api'
import { selectCompetition } from '../../../api/states/competitions.state'
import axios from 'axios'


const Registration = () => {
  const studentState = useSelector(selectStudent) || defaultStudent
  const [student, setStudent] = useState(studentState)
  const [application, setapplication] = useState(defaultCompApplication)
  const allCompetitions = useSelector(selectCompetition);
  const [competition, setCompetition] = useState<Competition>()
  const [streams, setStreams] = useState<Stream[]>([])
  const [stream, setStream] = useState("")

  const [subjects, setSubjects] = useState<Subject[]>([])
  const [selectedSubjects, setSelectedSubjects] = useState<Subject[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [level, setLevel] = useState<"ADVANCED" | "ORDINARY">()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!studentState.email) {
      localStorage.setItem('redirect', location.pathname)
      navigate(STUDENTSIGNUP)
    }
  }, [studentState, location])



  async function onSubmit(e: any) {



    e.preventDefault()
    console.log(application)
    window.scrollTo(0, 0)
    setLoading(true)
    setError("")
    setSuccess('')

    if (!competition) {
      setError("There is currently on going competition")
      return;
    }
    if (!level) {
      setError("Please select a level")
      return;

    }

    try {

      const lvl = getLevelFromId(level)
      const strm = getStreamFromId(stream)
      if (!lvl) throw new Error("Make sure to fill all the fields required");
      if (selectedSubjects.length <= 0) {
        throw new Error("Please select at least one subject");
      }

      const studentApp: CompetitionApplication = {
        level: {
          id: lvl?.id,
          name: lvl?.name,
          stream: {
            id: "",
            name: "",
            subjects: selectedSubjects
          }
        },
        name: competition?.name,
        student,
        timestamp: Date.now()
      }

      await registerForCompetitition(studentApp)
      dispatch(updateStudent({ ...student }))


      let amount = selectedSubjects.length == 1 ? 2000 : selectedSubjects.length == 2 ? 3000 : selectedSubjects.length == 3 ? 4000 : selectedSubjects.length == 4 ? 4000 : subjects.length * 2000;
      if (level == "ORDINARY") {
        amount = selectedSubjects.length * 1000;
      }
      const a = document.createElement('a')
      a.target = "__blank"
      a.href = `https://zitopay.africa/sci/?currency=XAF&amount=${amount}&receiver=obendesmond&memo=stem+competition&ref=${studentApp.student.email}&notification_url=${"https://payment-backend-desmond.up.railway.app/kc/payment"}`
      a.click()


    } catch (err: any) {
      if (typeof err == "string") {
        setError(err)
      } else {
        setError(err.message)
      }
    }
    setLoading(false)
  }

  useEffect(() => {

    if (!level) {
      return;
    }

    setStream("")
    const streamList = getLevelFromId(level)?.streams
    if (streamList) {
      setStreams(streamList)
      let subjects: Subject[] = [];
      streamList.map((item) => {
        subjects = [...subjects, ...item.subjects]
      })
      if (subjects) {
        setSubjects(subjects)
      }
    }


  }, [level])

  // useEffect(() => {

  //   if (stream && level) {
  //     const subjects = getLevelFromId(level)?.streams.find(str => str.id == stream)?.subjects
  //     if (subjects) {
  //       setSubjects(subjects)
  //     }
  //   }

  // }, [stream])

  function addRemoveSubject(subject: Subject) {
    const selected = selectedSubjects.find(sub => sub.name == subject.name);

    if (selected) {
      setSelectedSubjects((subs) => subs.filter(sub => sub.name != subject.name))
    } else {
      setSelectedSubjects([...selectedSubjects, subject])
    }
  }


  useEffect(() => {
    if (studentState) {
      setStudent(studentState)
    }
  }, [studentState])


  function getLevelFromId(levelId: "ADVANCED" | "ORDINARY") {

    return competition?.levels.find(lvl => lvl.id == levelId)
  }

  function getStreamFromId(streamId: string) {
    if (!level) {
      return;
    }
    return getLevelFromId(level)?.streams.find(str => str.id == streamId)
  }

  useEffect(() => {

    if (allCompetitions.length > 0) {
      console.log(allCompetitions)
      const latestCompetitions = allCompetitions.filter(competition => competition.accepting).sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
      if (latestCompetitions.length > 0) {
        setCompetition(latestCompetitions[0])
        console.log(latestCompetitions)
      }
    }

  }, [allCompetitions])


  // function makeRequest() {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     }

  //   }

  //   const data = new FormData()
  //   data.append("data", "amazing data to test")


  //   // axios.post("https://payment-server-ny0x.onrender.com/kc/payment", data, config).then((res) => {
  //   axios.post("http://localhost:8080/kc/payment/", data, config).then((res) => {
  //     console.log(res)
  //   }).catch(err => {
  //     console.log("ERRROR = >", err)
  //   })

  // }


  return (
    <div className='p-5 min-h-screen' >
      <div className='grid lg:grid-cols-3'>
        <div className='h-0 md:h-auto'>

        </div>
        <div className='col-span-1'>
          {success && <Alert severity='info'>{success}</Alert>}
          {error && <Alert severity='error'>{error}</Alert>}
          <form onSubmit={onSubmit} action="">
            <div className='mx-10 my-14 font-light text-3xl '>Register For Upcoming Competition {loading && <CircularProgress />}</div>
            <div className='mb-6 w-full'>
              <TextField required disabled={loading} value={student.name} onChange={(e) => setStudent({ ...student, name: e.target.value })} className='w-full' id="outlined-basic" label="Names" variant="outlined" />
            </div>
            <div className='mb-6 w-full'>
              <InputLabel className='mb-2'>Form / Class</InputLabel>
              <Select
                className='w-full'
                label="Level"
                value={student.form} onChange={(e) => setStudent({ ...student, form: (e.target.value) })}
              >
                {
                  defaultClasses.map((form, index) => {
                    return (
                      <MenuItem value={form} key={index} className='capitalize'>{form}</MenuItem>
                    )
                  })
                }

              </Select>
            </div>
            <div className='mb-6 w-full'>
              <TextField required disabled={loading} value={student.school} onChange={(e) => setStudent({ ...student, school: e.target.value })} className='w-full' id="outlined-basic" label="School" variant="outlined" />
            </div>

            <div className='mb-6 w-full'>
              <InputLabel className='mb-2'>Level</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={level}
                className='w-full'
                label="Level"
                onChange={(e) => { setLevel(e.target.value as "ADVANCED") }}
              >
                <MenuItem value={"ADVANCED"}>Advanced Level</MenuItem>
                <MenuItem value={"ORDINARY"}>Ordinary Level</MenuItem>
              </Select>
            </div>

            {/* <div className='mb-6 w-full'>
              <InputLabel className='mb-2'>Streams</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stream}
                className='w-full'
                label="Level"
                onChange={(e) => { setStream(e.target.value) }}
              >
                {
                  streams.map((stream, key) => <MenuItem key={key} value={stream.id}>{stream.name}</MenuItem>)
                }

              </Select>
            </div> */}
            {
              streams.map((stream, key) => {
                return (
                  <div className="p-4 mb-2">
                    <div className="text-xl mb-4">
                      {stream.name}
                    </div>
                    <div>
                      {stream.subjects.map((subject) => {

                        return <div>
                          {subject.id && <div className='mb-3 w-full'>
                            <FormControlLabel control={<Checkbox checked={!!selectedSubjects.find(sub => sub.id == subject.id)} value={subject.id} onChange={(e) => { addRemoveSubject(subject) }} />} label={subject.name} />
                          </div>}
                        </div>
                      }
                      )}
                    </div>
                  </div>
                )
              })
            }


            <div className={loading ? "opacity-50" : "opacity-100"}>
              <button disabled={loading} type='submit' className='button rounded-md my-14 w-full'>Register </button>
            </div>
          </form>

          {/* <button onClick={makeRequest} className='button rounded-md my-14 w-full bg-danger'>Test </button> */}

          <div className="h-10"></div>
        </div>
        <div></div>
      </div>

    </div>
  )
}

export default Registration