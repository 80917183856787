import { uuidv4 } from '@firebase/util'
import { Alert, Button, CircularProgress, MenuItem, Select, selectClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { HiDocument } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createResource, getAllResources } from '../../../api/firebase/resource.api'
import { selectAdmin } from '../../../api/states/admin.state'
import ResourceCard from '../../../components/Resources/ResourceCard.component'
import Searchbar from '../../../components/Searchbar/Searchbar.component'
import { ADMINEDITRESOURCE } from '../../../constants/routes.constant'
import { defaultClasses, fileTypes } from '../../../data/default.data'
import { Resource } from '../../../interfaces/resource.interface'

const ResourcesAdmin = () => {
    const [selectedClasses, setSelectedClasses] = useState<string[]>([defaultClasses[0]])
    const [selectedType, setSelectedType] = useState<string>(fileTypes[0])
    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")
    const [description, setdescription] = useState("")
    const [resources, setResources] = useState<Resource[]>([])
    const admin = useSelector(selectAdmin)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [selectedClass, setSelectedClass] = useState("All")
    const [selectedFilterType, setselectedFilterType] = useState("All")
    const [displayedResources, setDisplayedResources] = useState(resources)
    const [queryText, setQueryText] = useState("")

    const navigate = useNavigate()

    useEffect(() => {


        getAllResources((resources) => {
            setResources(resources)
            if (displayedResources.length <= 0) {
                setDisplayedResources(resources)
            }
        })

    }, [])


    async function createNewResource(e: any) {
        e.preventDefault()
        if (!admin) {
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')
        setLoading(true)
        const resource: Resource = {
            author: admin,
            forms: selectedClasses,
            id: uuidv4(),
            link,
            description,
            title,
            timestamp: Date.now(),
            type: selectedType as "DOCUMENT"
        }

        try {
            await createResource(resource);
            setSuccess("Successfully created resource")

        } catch (err: any) {
            setError(err?.message);
        }
        setLoading(false)
    }



    useEffect(() => {

        let list = [];
        list = resources.filter((res) => {
            if (selectedFilterType == "All" && selectedClass == "All") {
                return true
            }
            if (selectedFilterType == "All") {
                return res.forms.includes(selectedClass)
            }
            if (selectedClass == "All") {
                return res.type == selectedFilterType;
            }
            return (res.type == selectedFilterType && res.forms.includes(selectedClass))
        })
        setDisplayedResources(list)

        return () => {

        }
    }, [selectedFilterType, selectedClass])

    function searchText(text: string) {
        setQueryText(text)
        if (!text) {
            setDisplayedResources(resources)
        }

        setDisplayedResources(() => resources.filter(res => res.title.toLowerCase().match(text.toLowerCase())))
        setselectedFilterType("All")
        setSelectedClass("All")
    }


    return (
        <div className='p-5 min-h-screen'>
            <div className="text-xl">Resources</div>
            <div className="p-2 my-4 border border-gray-200 rounded-md">
                <div className="p-2">
                    {success && <Alert severity='info'>{success}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                </div>
                <form onSubmit={createNewResource}>
                    <div className="mt-5 mb-2">Add New {loading && <CircularProgress />} <Button type='submit' className='float-right'  >Add</Button></div>
                    <div className="grid md:grid-cols-4 p-3 gap-5">

                        <div><input required type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Enter Title' className='p-2 border rounded h-full w-full' /></div>
                        <div><input type='url' required placeholder='Link' value={link} onChange={(e) => setLink(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div><input required type="text" placeholder='Description' value={description} onChange={(e) => setdescription(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div className="grid md:grid-cols-2 gap-x-2">
                            <Select required multiple title='Class' className='bg-white' value={selectedClasses} onChange={(e) => setSelectedClasses((e.target.value as string[]))} fullWidth>
                                {
                                    defaultClasses.map((form) => {
                                        return (<MenuItem value={form}>{form}</MenuItem>)
                                    })
                                }
                            </Select>

                            <Select required title='file type' value={selectedType} className='mt-4 bg-white' onChange={(e) => setSelectedType((e.target.value as string))} fullWidth>
                                {
                                    fileTypes.map((form) => {
                                        return (<MenuItem value={form}>{form}</MenuItem>)
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </form>
            </div>
            <div className="h-24"></div>
            <div className="my-6 text-xl">All Resources</div>
            <div className="grid  md:grid-cols-7 gap-6 items-center">
                <div>
                    <Select fullWidth value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                        {
                            [...defaultClasses, "All"].map((form, index) => {
                                return (<MenuItem value={form}>{form}</MenuItem>)
                            })
                        }
                    </Select>
                </div>
                <div >
                    <Select fullWidth value={selectedFilterType} onChange={(e) => setselectedFilterType(e.target.value)}>
                        {
                            [...fileTypes, "All"].map((form, index) => {
                                return (<MenuItem value={form}>{form}</MenuItem>)
                            })
                        }
                    </Select>
                </div>

                <div className='md:col-span-4'>
                    <Searchbar text={queryText} onChange={searchText} />
                </div>

            </div>
            <div className="mt-10">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
                    {
                        displayedResources.map((resource) => {
                            return (<ResourceCard resource={resource} />)
                        })
                    }
                </div>
            </div>
        </div>


    )
}




export default ResourcesAdmin



