import { LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { fetchTopBlogPost } from '../../../api/firebase/blog.api'
import Searchbar from '../../../components/Searchbar/Searchbar.component'
import { BlogPost } from '../../../interfaces/blog.interface'

const BlogCategories: React.FC = () => {

    const [blogPosts, setBlogPosts] = useState<BlogPost[]>([])
    const [selectedClass, setSelectedClass] = useState("All")
    const [selectedType, setselectedType] = useState("All")
    const [displayedPosts, setDisplayedPosts] = useState(blogPosts)
    const [queryText, setQueryText] = useState("")
    const [loading, setLoading] = useState(false)
    const location = useLocation()


    useEffect(() => {
        getTopPosts()
    }, [])

    async function getTopPosts() {
        setLoading(true)
        try {
            const posts = await fetchTopBlogPost()
            setBlogPosts([...posts])
            if (location.pathname.match("blog")) {
                setDisplayedPosts([...posts])
            } else {
                setDisplayedPosts([...posts].filter((p, index) => index <= 6));
            }
        } catch (err) {

        }
        setLoading(false)
    }


    useEffect(() => {

        // let list = [];
        // list = blogPosts.filter((res) => {
        //     if (selectedType == "All" && selectedClass == "All") {
        //         return true
        //     }
        //     if (selectedType == "All") {
        //         return res.forms.includes(selectedClass)
        //     }
        //     if (selectedClass == "All") {
        //         return res.type == selectedType;
        //     }
        //     return (res.type == selectedType && res.forms.includes(selectedClass))
        // })
        // setDisplayedPosts(list)

        // return () => {

        // }
    }, [selectedType, selectedClass])

    function searchText(text: string) {
        setQueryText(text)
        if (!text) {
            setDisplayedPosts(blogPosts)
        }

        setDisplayedPosts(() => blogPosts.filter(res => {
            const cond1 = res.title.toLowerCase().match(text.toLowerCase())
            const cond2 = res.description.toLowerCase().match(text.toLowerCase())
            const cond3 = res.tags.includes(text.toLowerCase())

            return cond1 || cond2 || cond3

        }))
        setselectedType("All")
        setSelectedClass("All")
    }




    return (
        <div>
            {loading && <LinearProgress />}
            {location.pathname.match("blog") && <div className='p-5'>
                <Searchbar text={queryText} onChange={(text) => searchText(text)} />
            </div>}
            <div className="p-2"
            >
                <h3 className="text-2xl my-3 mb-7">Top Posts</h3>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-8 mb-20">
                    {
                        displayedPosts.map((obj, index) => {

                            return (
                                <Link to={`/blog/${obj.id}`}>
                                    <div >
                                        <img className='w-full md:h-[200px] object-cover' src={obj.image} alt="" />
                                        <div className="text-xl my-2">{obj.title}</div>
                                        <div>
                                            {obj.description}
                                        </div>
                                        <div className='text-sm text-gray-600 my-2'>
                                            {new Date(obj.timestamp).toDateString()}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default BlogCategories

