import { Clipboard } from '@capacitor/clipboard'
import { Alert, Avatar, Button, CircularProgress, Fab } from '@mui/material'
import React, { useState } from 'react'
import { BsArrowRight, BsShare } from 'react-icons/bs'
import { HiThumbUp, HiThumbDown } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { commentOnPost, dislikePost, likePost } from '../../api/firebase/blog.api'
import { selectStudent } from '../../api/states/student.state'
import { STUDENTLOGIN } from '../../constants/routes.constant'
import { BlogPost } from '../../interfaces/blog.interface'

const ReactionControls: React.FC<{ post: BlogPost }> = ({ post }) => {

    const student = useSelector(selectStudent);
    const isLiked = !!post.reaction?.likes.find(like => like.user.email == student?.email);
    const isDisLiked = !!post.reaction?.dislikes.find(dislike => dislike.user.email == student?.email);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [text, setText] = useState("")



    async function like() {

        if (!student) {
            setError("Please login to your student account first");
            return;
        }
        // setLoading(true)
        setError("")
        setSuccess('')

        try {
            await likePost(post.id, student)
            console.log("liked")
        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
        // setLoading(false)

    }

    async function dislike() {

        if (!student) {
            setError("Please login to your student account first");
            return;
        };

        // setLoading(true)
        setError("")
        setSuccess('')

        try {
            await dislikePost(post.id, student)
        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
        // setLoading(false)

    }

    async function comment() {
        if (!student) {
            setError("Please login to your student account first");
            return;
        };
        setError("")
        setSuccess('')
        if (!text) {
            return;
        }


        try {

            await commentOnPost(post.id, student, text);
            setText("")

        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }

    }

    async function share() {
        setError("")
        setSuccess('')
        try {

            await Clipboard.write({ url: "https://knowledgecentercmr.web.app/blog/" + post.id });
            setSuccess("Link copied to clipboard")

        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
    }

    return (
        <>
            <div className="p-3">
                {success && <Alert severity='info'>{success} </Alert>}
                {error && <Alert severity='error'>{error} {!student && <Link to={STUDENTLOGIN}><Button color='error'>Login</Button></Link>}</Alert>}
            </div>
            <div className="font-light text-sm">  {loading && <CircularProgress />}</div>

            <div className="p-3 grid grid-cols-3">
                <Button onClick={like} color='inherit' className={`p-2 mr-3`}>
                    <HiThumbUp className={`inline-block text-[20px] mr-2  ${isLiked ? 'text-blue-500' : 'text-black'}`} /> <span className={`${isLiked ? 'text-blue-500' : 'text-black'}`}>Like</span>
                </Button>
                <Button onClick={dislike} color='inherit' className={`p-2 mr-3 `}>
                    <HiThumbDown className={`inline-block text-[20px] mr-2 ${isDisLiked ? 'text-blue-500' : 'text-black'} `} /> <span className={`${isDisLiked ? 'text-blue-500' : 'text-black'}`}>Dislike</span>
                </Button>
                <Button onClick={share} color='inherit' className="p-2">
                    <BsShare className={`inline-block text-[16px] mr-2 `} /> Share
                </Button>
            </div>
            {post?.reaction && <div className="p-2 text-[13px] text-gray-500 w-full">
                {post?.reaction?.likes.length} likes, {post?.reaction.comments.length} comments
            </div>}

            <div className="border bg-white rounded px-2 flex">
                <div> <Avatar sx={{ transform: "scale(0.7)" }} className='inline-block mt-2'>{student?.name[0]}</Avatar></div>
                <input value={text} onChange={(e) => setText(e.target.value)} type="text" className='inline-block w-full bg-transparent outline-none pl-4 text-sm' placeholder='Enter comment...' />
                <div>
                    <Fab onClick={comment} color='primary' sx={{ transform: "scale(0.7)" }}>
                        <BsArrowRight className='font-bold text[20px]' />
                    </Fab>
                </div>
            </div>
        </>
    )
}

export default ReactionControls