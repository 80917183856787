
import { uuidv4 } from '@firebase/util'
import { Alert, Button, CircularProgress, MenuItem, Select, selectClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { BsLinkedin } from 'react-icons/bs'
import { HiDocument } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createStaff, deleteStaff, getAllStaff } from '../../../api/firebase/auth.api'
import { createResource, getAllResources } from '../../../api/firebase/resource.api'
import { selectAdmin } from '../../../api/states/admin.state'
import ResourceCard from '../../../components/Resources/ResourceCard.component'
import Searchbar from '../../../components/Searchbar/Searchbar.component'
import { ADMINEDITRESOURCE } from '../../../constants/routes.constant'
import { defaultClasses, fileTypes } from '../../../data/default.data'
import { Staff } from '../../../interfaces/auth.interface'
import { Resource } from '../../../interfaces/resource.interface'

const AdminStaffs = () => {

    const [name, setName] = useState("")
    const [image, setImage] = useState("")
    const [description, setdescription] = useState("")
    const [contact, setContact] = useState("")
    const [social, setSocial] = useState("")
    const admin = useSelector(selectAdmin)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [displayedStaff, setDisplayedStaff] = useState<Staff[]>([])
    const [staff, setStaff] = useState<Staff[]>([])

    const [queryText, setQueryText] = useState("")

    const navigate = useNavigate()

    useEffect(() => {


        getAllStaff((staff) => {
            setStaff(staff)
            if (displayedStaff.length <= 0) {
                setDisplayedStaff(staff)
            }
        })

    }, [])


    async function createNewResource(e: React.FormEvent) {
        e.preventDefault()

        if (!admin) {
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')
        setLoading(true)
        const staff: Staff = {
            id: uuidv4(),
            name: name,
            linkedin: social,
            description: description,
            contact: contact,
            photo: image
        }

        try {
            await createStaff(staff);
            setSuccess("Successfully created staff")
            setName("")
            setSocial("")
            setContact("")
            setImage("")

        } catch (err: any) {
            setError(err?.message);
        }
        setLoading(false)
    }

    async function deleteThisStaff(id: string) {
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')
        setLoading(true)
        try {
            await deleteStaff(id);
            setSuccess("Successfully deleted staff")
            setName("")
            setSocial("")
            setContact("")
            setImage("")

        } catch (err: any) {
            setError(err?.message);
        }
        setLoading(false)

    }

    return (
        <div className='p-6  min-h-screen'>
            <div className="text-2xl my-3 mb-4"> Staff</div>
            <div className="p-3 py-2">
                <div className="p-2">
                    {success && <Alert severity='info'>{success}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                </div>
                <form onSubmit={createNewResource}>
                    <div className="mt-5 mb-2">Add New {loading && <CircularProgress />} <Button type='submit' className='float-right'  >Add</Button></div>
                    <div className="grid md:grid-cols-3 p-3 gap-5">

                        <div><input required type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Staff Name' className='p-2 border rounded h-full w-full' /></div>
                        <div className='flex'>
                            {image && <img src={image} className='w-10 h-10 object-cover' />}
                            <input type='url' required placeholder='staff photo' value={image} onChange={(e) => setImage(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div><input required type="text" placeholder='Bio' value={description} onChange={(e) => setdescription(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div><input required value={contact} onChange={(e) => setContact(e.target.value)} placeholder='Staff Email or Phone' className='p-2 border rounded h-full w-full' /></div>
                        <div><input type="url" value={social} onChange={(e) => setSocial(e.target.value)} placeholder='Linkedin' className='p-2 border rounded h-full w-full' /></div>
                    </div>
                </form>
                <div className="mt-10">
                    <div className="text-xl mb-5">Present Staff</div>
                    <div className="grid grid-cols-3">
                        {
                            displayedStaff.map((staff, index) => {
                                return (
                                    <div className='border rounded'>
                                        <img src={staff.photo} className='w-full h-[300px]' alt="" />
                                        <div className="p-2">
                                            <div className="text-xl">{staff.name}</div>
                                            <div>{staff.description}</div>
                                            <div className="text-center">
                                                {staff.linkedin && <a href={staff.linkedin}> <BsLinkedin className='text-[20px] inline-block' /></a>}
                                            </div>
                                            <div>
                                                <Button onClick={() => deleteThisStaff(staff.id)} color='error'>
                                                    <BiTrash /> Delete
                                                </Button>
                                                {/* <Button color='info'>
                                                    <BiPencil /> Edit
                                                </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminStaffs