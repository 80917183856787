import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import coverImage from '../../../assets/images/COVER IMAGE.png'
import { LANDINGDONATION, STUDENTLOGIN, STUDENTREGISTRATION, STUDENTSIGNUP } from '../../../constants/routes.constant'
import group3 from '../../../assets/KC pictures/group3.jpg'
import class1 from '../../../assets/KC pictures/class1.jpg'
import caleb from '../../../assets/KC pictures/caleb.jpeg'
import rachel from '../../../assets/KC pictures/rachel.jpeg'
import group1 from '../../../assets/KC pictures/group1.jpg'
import award1 from '../../../assets/KC pictures/award1.jpg'
import award2 from '../../../assets/KC pictures/award2.jpg'
import flyer from '../../../assets/KC pictures/flyer.jpg'
import class4 from '../../../assets/KC pictures/class4.jpg'
import circleFrame from '../../../assets/images/circleFrame.png'
import squareFrame from '../../../assets/images/squareFrame.png'
import Tframe from '../../../assets/images/Tframe.png'
import { Chip, TextField } from '@mui/material'
import { SiFacebook } from 'react-icons/si'
import { FaTwitterSquare } from 'react-icons/fa'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'
import { FaGooglePlusSquare } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { selectStudent } from '../../../api/states/student.state'



const StemCompetitionInfo: React.FC = () => {

  const student = useSelector(selectStudent)
  const navigate = useNavigate()

  function registerForStemCompetition() {
    if (student) {
      navigate(STUDENTREGISTRATION)
    } else {
      navigate(STUDENTSIGNUP)
    }
  }

  function Donate() {
    navigate(LANDINGDONATION)
  }


  return (
    <div>
      <div className=' bg-white'>
        <div className='grid bg-black'>

          <div className='relative'>
            <img src={award1} className="w-full h-[300px] object-cover" alt="" />
            <div className="absolute bg-black bg-opacity-80 top-0 left-0 w-full h-full">
              <div className="w-full h-full relative">
                <div className="text-white text-4xl absolute top-1/2 w-full text-center font-light" style={{ letterSpacing: "10px" }}>KC STEM COMPETITION 2022</div>
              </div>
            </div>

          </div>

        </div>

        <div className="grid md:grid-cols-12 mt-10">
          <div></div>
          <div></div>
          <div className="col-span-7  p-3 px-6 py-9">
            <div className="py-4 text-center mb-10">
              <img src={flyer} className='max-w-screen object-cover  max-h-[90vh]  inline-block' alt="" />
            </div>
            <div className='text-left mb-12'>
              <div className='text-4xl font-bold tracking-wide mr-28 mb-10'>KC STEM COMPETITION 2022</div>
              <div className='mt-10 text-2xl mb-4 font-bold'>What is the KC STEM Competition? </div>


              <div>
                <p>The KC STEM (Science, Technology, Engineering, and Mathematics) Competition, whose
                  first edition took place in December of 2021, is an annual national (Cameroon) scientific contest
                  organized by Knowledge Center (KC) in an
                  effort to foster scientific thinking in students.
                </p>
                <p className='mt-10'> We challenge participants with thought-provoking
                  questions which force them to think beyond the ordinary student’s mental reach, and, perhaps for
                  the first time, unlock the floodgates of creativity and critical thinking.
                </p>
                <p className='mt-2'>
                  At the end of this annual
                  contest (and here, since the competition is only a year old, we say “annual” in the confident hope
                  that the competition will carry on in perpetuity), after having evaluated their performances, we
                  reward the best students for their scientific enthusiasm and mastery; with this, we pass across a
                  simple resounding message: “Science is so important in our world, and we must keep young
                  minds ablaze with love for it.”
                </p>
                <div className="mt-3">
                  <Link to={STUDENTREGISTRATION}> <button className="button bg-dark text-danger px-5 w-auto  btn">Register Now</button></Link>
                </div>

              </div>

            </div>

            <div className="text-2xl font-bold  my-2 mb-7">Why organize the KC STEM Competition? </div>
            <p className="">
              The competition was started for two primary reasons:
              <ul className='mt-2'>
                <li>  • To foster curiosity and creativity in an ever-evolving world of science, which is in line
                  with our vision of inspiring a generation of creative thinkers.</li>
                <li>• To reward scientific thinking.</li>
              </ul>
            </p>
            <div className="p-3 text-center mt-10">
              <img src={class4} alt="" />
            </div>


            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-7 mt-">Structure of the exams</div>
              <p className="mt-2">

                This second edition, like the first, will consist of a written exam only. Four subject entries will
                be tested: Biology, Chemistry, Mathematics, and Physics; these subjects occur in groups
                (streams) of 2.
              </p>
              <p className="mt-2">
                Physics and Mathematics are collectively termed Stream A, while Biology and
                Chemistry form Stream B. This does not mean a student must participate in a stream; every
                participant has the freedom to choose whatever combination of entries they wish to participate
                in.
              </p>
              <p className="mt-2">
                The classification in streams has some significance nonetheless, and the nuances of this will
                be made evident in the Rewards section of this article.
              </p>
              <p className="mt-2">
                The exam is organized in two levels: ordinary and advanced level.
              </p>
            </div>

            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-7 mt-">Ordinary Level</div>
              <p className="mt-2">

                At the ordinary level, only Stream A will be tested. Participants can choose to write either of the
                subjects that occur there, or both.
                Advanced Level.
              </p>
              <p className="mt-8">
                <div className="text-2xl font-bold my-2 mb-7 mt-">Advanced Level</div>
                At the advanced level, both streams will be tested. Here, participants can choose whatever
                combination of subjects (Biology, Chemistry, Physics, and Mathematics) to compete in. The
                concept of streams still holds!
              </p>
            </div>

            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-3 mt-"> Question Setting and Exam Duration</div>
              <p className="mt-2">
                The exam will consist (for both levels, and for each subject entry) of 50 multiple choice
                questions and 3 short structural questions which will be written for 3 hours.
              </p>
            </div>

            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-3 mt-">Scope</div>
              <p className="mt-2">
                For each level, and for their corresponding entries, the questions will be set, for the most part, in
                agreement with the national syllabus put in place by The Cameroon GCE Board; however, a few
                concepts will be tested which fall outside of this framework. The topical scope for each subject
                at the various levels are provided in the documents below.
              </p>
              <a href="https://drive.google.com/file/d/1sq9hG96xhxLG9oVPSM09mKPydWHIWccK/view?usp=drivesdk" target='_blank'>
                <Chip color='info' className='mt-4 cursor-pointer' label='KC STEM 2022 Subject Scope'></Chip>
              </a>
              <b className="mt-3">

              </b>
              <p className="mt-6">
                We understand that in such a rigorous scientific scape, a written exam only is inadequate. We
                hope in future editions to make the exams more practical by providing opportunities for the
                students to work on science projects, and to make use of technological tools.
              </p>
            </div>


            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-3 mt-2">
                Prizes and rewards
              </div>
              <div className="mt-4">
                The grand national prizes can only be awarded to students who participated in a stream; the best
                student is gotten by tallying up their marks for the subject entries contained within the stream. A
                national prize will be awarded for each stream.
                The reward structures for both levels are outlined below:
              </div>

              <div className="mt-8">
                <div className="font-bold text-lg mb-3">
                  Advanced Level
                </div>
                <div className="mx-5">
                  <div className="mb-3">• National best for each stream: 50,000 frs</div>
                  <div className="mb-4">• Second best for each stream: 30,000 frs</div>
                  <div className="mb-4">• Third best for each stream: 20,000frs</div>
                  <div className="mb-4">• National best from each subject entry: 10, 000frs</div>
                  <div className="mb-4">• Best from Each school: KC T-shirts</div>
                  <div className="mb-4">• Each Candidate gets a certificate of participation.</div>
                </div>
              </div>

              <div className="mt-8">
                <div className="font-bold text-lg mb-3">
                  Ordinary Level
                </div>
                <div className="mx-5">
                  <div className="mb-3">• National best for each stream (cumulative Maths and Physics score): 50,000 frs </div>
                  <div className="mb-4">• Second best for each stream: 30,000 frs</div>
                  <div className="mb-4">• Third best for each stream: 20,000frs</div>
                  <div className="mb-4">• National best from each subject entry: 5, 000frs</div>
                  <div className="mb-4">• Best from Each school: KC books and pens</div>
                  <div className="mb-4">• Each Candidate gets a certificate of participation.</div>
                </div>
              </div>

            </div>



            <div className="mt-10">
              <div className="text-2xl font-bold my-2 mb-7 mt-"> Eligibility</div>
              <p className="mt-2">

                For the Ordinary level, students from form three to form five can participate.
                For the advanced level, students of lower sixth and upper sixth can participate.

              </p>

              <div className="text-2xl font-bold my-2 mb-7 mt-10">  Registration</div>

              <p className="mt-2 font-bold">

                The candidate is considered registered once he/she has paid their registration fee.

              </p>



            </div>

            <div className="mt-10">
              <p className="mt-8 ">
                <div className="text-xl font-bold my-2 mb-3mt-">Ordinary Level</div>
                <ul className='mb-7'>
                  1000frs for each subject entry
                </ul>
                <div className="text-xl font-bold my-2 mb-3 mt-">Advanced Level</div>


                <ul>
                  One subject entry – 2000frs <br />
                  Two subject entries – 3000frs <br />
                  Three subject entries – 4000frs <br />
                  Four subject entries – 4000frs
                </ul>
              </p>
            </div>

            <div>
              <div>
                <ul>
                  <div className="my-3 font-bold mb-5">
                    The registration fee, once paid, is non-refundable.
                  </div>
                  <div className="mt-16 font-bold my-2 mb-5 text-2xl">Why you should participate</div>
                  <ul>
                    <li className='mb-4'> •  Challenge yourself with problems that force you to think outside the box</li>
                    <li className='mb-4'> • Stand a chance of winning national and international acclaim alongside huge cash prizes</li>
                    <li className='mb-4'> •  Broaden your scope of understanding of various scientific concepts which will be useful if you intend to further your studies abroad.</li>
                    <li className='mb-4'> •  Win certificates of participation which will be recognized nationally</li>
                  </ul>


                </ul>
              </div>
              <p className="mt-16">
                <div className="text-2xl font-bold my-2 mb-7 mt-">Important Dates</div>
                <ul>
                  • <b>November 21st</b>  – Registration  opens <br />
                  •  <b>December 19th</b>  – Registration closes <br />
                  •  <b>December 21st</b> – Writing of stream A for both levels <br />
                  •  <b>December 22nd</b>  – Writing of stream B <br />
                  •  <b>February 1st</b> – Publication of results <br />
                  • <b> March 15th</b> – Award of prizes
                </ul>

              </p>
            </div>
            <div>
              <div className="mt-10 text-2xl font-bold"> Writing centers</div>
              <ul>
                <div className="p-1 px-3">
                  • Buea – Catholic university (CUIB)
                </div>
                <div className="p-1 px-3">
                  • Tiko – Sure Foundation
                </div>
                <div className="p-1 px-3">
                  • Limbe – College D’Excellence
                </div>
                <div className="p-1 px-3">
                  • Kumba – Victoria primary school
                </div>
                <div className="p-1 px-3">
                  • Douala
                </div>
                <div className="p-1 px-3">
                  • Yaounde
                </div>
                <div className="p-1 px-3">
                  •  Bamenda
                </div>
                <div className="p-1 px-3">
                  • West region
                </div>
              </ul>

            </div>

            <div className="mt-20 ">
              <div className="text-2xl font-bold my-2 mb-10">  Last year’s participants share their experience </div>

              <div className="text-center mt-7 p-4 border rounded mb-10">
                <div className="p-3 text-center">
                  <img src={rachel} className="w-[200px] h-[200px] object-cover object-top inline-block" />
                </div>
                <div className="ml text-center m-6 text-gray-700">  - - Taking the STEM as an Uppersixth student greatly my performance in school as I was introduced problems which honed my thinking & learning levels and I began to grasp some concepts better. I was introduced to more academically exposed mentors who I believe I learnt a lot from and was inspired to aim a lot higher than I previously did
                  Although, I didn’t win, the STEM was a fun and insightful experience and I feel lucky to have been part of such a wholesome event.
                </div>
                <div className="mt-3">
                  Agyingi Rachel – 2021 STEM Competition participant
                </div>
              </div>

              <div className="text-center mt-7 p-4 border rounded mb-10">
                <div className="p-3 text-center">
                  <img src={caleb} className="w-[200px] h-[200px] object-cover object-top inline-block" />
                </div>
                <div className="ml text-center m-6 text-gray-700">
                  - - Taking the STEM was very eye opening to me, I became exposed to interesting questions which challenged me to think out of the box. I was just a Lower sixth kid then and becoming the National Best made me feel proud and encouraged to do more.

                  <p>
                    Besides the handsome cash prize (50000frs) which I won, and which has helped me afford some of my educational needs, KC has continued to mentor and teach me until now. The competition has won me national recognition, and we was also awarded a certificate.

                  </p>

                  <p>
                    The KC STEM competition has changed me forever. I remain grateful for such a life-changing opportunity.
                  </p>
                  <p>
                    Overall, the STEM was a very positive experience for me, which has only increased my hunger for success especially in the upcoming GCE exams

                  </p>

                </div>
                <div className="mt-3">
                  Nkengfua Caleb – 2021 STEM Competition first-prize winner.
                </div>
              </div>

            </div>

          </div>
          <div className='col-span-12 md:col-span-3 mb-20'>
            <div className="sticky top-20">
              <div className="m-5 ">
                <div className="border w-full rounded p-5 text-center md:mt-32 mt-10">
                  <div className='py-3  text-xl mb-4'>Please Donate to sponsor the competition</div>
                  <div className="text-center"><button onClick={() => { Donate() }} className='bg-danger py-3 px-8 text-white rounded'>Donate</button></div>
                </div>
              </div>
              <div className="m-5">
                <div className="border rounded p-5 text-center md:mt-20 mt-10">

                  <div className='py-3  text-xl mb-4' >Register for competition</div>
                  <div className="text-center"><button onClick={registerForStemCompetition} className='bg-black text-white py-3 px-8 rounded'>Register</button></div>
                </div>
              </div>
            </div>
          </div>




        </div>



      </div>
    </div >
  )
}

export default StemCompetitionInfo