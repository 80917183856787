import { uuidv4 } from '@firebase/util'
import { Alert, Button, CircularProgress, MenuItem, Select, selectClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { BsEye } from 'react-icons/bs'
import { HiDocument } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { createResource, deleteResource, getAllResources } from '../../../api/firebase/resource.api'
import { selectAdmin } from '../../../api/states/admin.state'
import { defaultClasses, fileTypes } from '../../../data/default.data'
import { Resource } from '../../../interfaces/resource.interface'

const EditResourceAdmin = () => {
    const [selectedClasses, setSelectedClasses] = useState<string[]>([defaultClasses[0]])
    const [selectedType, setSelectedType] = useState<string>(fileTypes[0])
    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")
    const [description, setdescription] = useState("")
    const admin = useSelector(selectAdmin)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [resourceId, setResourceId] = useState("")

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        const state = location.state as { resource: Resource }
        if (state && state.resource) {
            setSelectedClasses(state.resource.forms)
            setTitle(state.resource.title)
            setdescription(state.resource.description)
            setLink(state.resource.link)
            setSelectedType(state.resource.type)
            setResourceId(state.resource.id)

        }

        return () => {

        }
    }, [location])



    async function createNewResource(e: any) {
        e.preventDefault()
        if (!admin || !location.state.resource) {
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')
        setLoading(true)
        const resource: Resource = {
            author: admin,
            forms: selectedClasses,
            id: location.state.resource.id,
            link,
            description,
            title,
            timestamp: Date.now(),
            type: selectedType as "DOCUMENT"
        }

        try {
            await createResource(resource);
            setSuccess("Successfully Edited resource")

        } catch (err: any) {
            setError(err?.message);
        }
        setLoading(false)
    }


    async function deleteThisResource() {

        if (!admin || !location.state.resource) {
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')
        setLoading(true)


        try {
            await deleteResource(resourceId);
            setSuccess("Successfully Deleted resource")
            navigate(-1)

        } catch (err: any) {
            setError(err?.message);
        }
        setLoading(false)
    }
    return (
        <div className='p-5 min-h-screen'>
            <div className="py-5 mb-10">
                <Button onClick={deleteThisResource} className='md:float-right' color='error'>
                    <BiTrash className='text-[20px]' /> <span className='ml-1'> Delete</span>
                </Button>

                <a href={link} target='_blank' className='md:float-right' >
                    <Button  >
                        <BsEye className='text-[20px]' /> <span className='ml-1'> Preview</span>
                    </Button>
                </a>
            </div>
            <div className="text-xl">Edit Resource</div>
            <div className="p-2 my-4 border border-gray-200 rounded-md">
                <div className="p-2">
                    {success && <Alert severity='info'>{success}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                </div>

                <form onSubmit={createNewResource}>
                    <div className="grid md:grid-cols-4 p-3 gap-5">
                        <div className="mt-5 mb-2 text-xl font-bold">Edit {loading && <CircularProgress />} <Button type='submit' className='float-right'  >Save</Button></div>

                        <div><input required type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Enter Title' className='p-2 border rounded h-full w-full' /></div>
                        <div><input type='url' required placeholder='Link' value={link} onChange={(e) => setLink(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div><input required type="text" placeholder='Description' value={description} onChange={(e) => setdescription(e.target.value)} className='p-2 border rounded h-full w-full' /></div>
                        <div className="grid md:grid-cols-2 gap-x-2">
                            <Select required multiple title='Class' className='bg-white' value={selectedClasses} onChange={(e) => setSelectedClasses((e.target.value as string[]))} fullWidth>
                                {
                                    defaultClasses.map((form) => {
                                        return (<MenuItem value={form}>{form}</MenuItem>)
                                    })
                                }
                            </Select>

                            <Select required title='file type' value={selectedType} className='mt-4 bg-white' onChange={(e) => setSelectedType((e.target.value as string))} fullWidth>
                                {
                                    fileTypes.map((form) => {
                                        return (<MenuItem value={form}>{form}</MenuItem>)
                                    })
                                }
                            </Select>
                        </div>

                    </div>
                </form>
            </div>

        </div>


    )
}




export default EditResourceAdmin



