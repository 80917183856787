import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Message } from '../../../interfaces/notification.interface'

const NotificationDetail = () => {
  const [notification, setNotification] = useState<Message>()
  const location = useLocation()
  const message = (location.state as { message?: Message }).message





  return (
    <div className='p-3 min-h-screen py-4 '>
      <div className="grid md:grid-cols-7">
        <div></div>
        <div className='border rounded md:col-span-5 p-4'>
          <div className="p-4 flex">
            <div className=''><Avatar>{message?.sender.name[0]}</Avatar></div>
            <div className='pt-2'>  <span className="mx-5 mt-10">{message?.sender.name}</span></div>
          </div>
          <div className="my-3 text-3xl">
            {message?.title}
            <span className="float-right text-sm font-normal">
              {new Date(message?.timestamp || Date.now()).toLocaleString()}
            </span>
          </div>
          <div className="my-3 mb-10 font-light ">
            {message?.description}
          </div>
          <div dangerouslySetInnerHTML={{ __html: message?.message || "" }}></div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default NotificationDetail