import React, { useEffect, useState } from 'react'
import Searchbar from '../../../components/Searchbar/Searchbar.component'
import BlogCategories from './BlogCategories.screen'


const Blog: React.FC = () => {

    return (
        <div className=''>
            <div className='grid py-10 px-10 md:px-0 md:grid-cols-5'>
                <div></div>
                <div className='col-span-2'>
                    <div className="text-4xl">Stay Curious</div>
                    <div className="my-5 text-2xl">
                        Discover stories, thinking, and expertise from writers on any topic.
                    </div>
                    <button className='bg-black p-3 rounded-full px-7 text-white'>
                        Start Reading
                    </button>
                </div>
                <div></div>
            </div>
            <div className='grid py-3 px-10 md:px-0 md:grid-cols-6 bg-white'>
                <div></div>
                <div className='col-span-4 py-10'>

                    <BlogCategories />
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Blog