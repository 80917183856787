import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAdmin } from '../../../api/states/admin.state'
import flynn from '../../../assets/images/RomeFlynn.jpeg'
import { Admin } from '../../../interfaces/auth.interface'

const ProfileAdmin = () => {

  const admin = useSelector(selectAdmin)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    if (admin) {
      setName(admin.name)
      setEmail(admin.email)
      console.log(admin)

    }
  }, [admin as Admin])


  return (
    <div className='p-7'>
      <div>
        <div className=''>
          <Avatar sx={{ background: "#3b75cb" }}> {name.length > 0 && name[0]}</Avatar>
        </div>

        <div className='grid sm:grid-cols-3 gap-x-14 my-8'>
          <div className='col-span-1 mb-5'>
            <div className='mb-2'>Your Name</div>
            <div ><input type="text" value={name} className='w-full border rounded  outline-primary px-2 py-1' /></div>
          </div>

          <div className='col-span-1 mb-5'>
            <div className='mb-3'>Email</div>
            <div><input type="text" value={email} className='w-full border rounded  outline-primary px-2 py-1' /></div>
          </div>

          <div className='col-span-1 mb-5'>
            <div className='mb-3'>Phone</div>
            <div><input value={phone} type="text" className='w-full  border rounded  outline-primary px-2 py-1' /></div>
          </div>
        </div>

        <div>
          <button className='button float-right border rounded '>Save</button>
        </div>

      </div>
    </div>
  )
}

export default ProfileAdmin

