import React from 'react'
import groupred3 from '../../../assets/KC pictures/groupred3.jpg'
import groupred4 from '../../../assets/KC pictures/groupred4.jpg'
import groupred1 from '../../../assets/KC pictures/groupred1.jpg'
import groupred2 from '../../../assets/KC pictures/groupred2.jpg'
import award1 from '../../../assets/KC pictures/award1.jpg'
import class1 from '../../../assets/KC pictures/class1.jpg'
import compete from '../../../assets/KC pictures/compete.jpg'
import flyer from '../../../assets/KC pictures/flyer.jpg'
import class2 from '../../../assets/KC pictures/class2.jpg'
import class3 from '../../../assets/KC pictures/class3.jpg'
import group1 from '../../../assets/KC pictures/group1.jpg'
import group2 from '../../../assets/KC pictures/group2.jpg'
import group3 from '../../../assets/KC pictures/group3.jpg'
import group4 from '../../../assets/KC pictures/group4.jpg'
import BlogCategories from '../Blog/BlogCategories.screen'
import { LANDINGBLOG, LANDINGDONATION, STUDENTLOGIN, STUDENTSIGNUP } from '../../../constants/routes.constant'
import { Link } from 'react-router-dom'
import LandingCarousel from '../../../components/LandingCarousel/LandingCarousel.component'
import { LandingImages } from '../../../assets/KC pictures'

const MainLandingPage: React.FC = () => {
    return (
        <div className='bg-white'>
            <div className='relative'>
                <img src={groupred3} className="w-full h-[50vh] object-cover" alt="" />
                <div className="absolute bg-black bg-opacity-80 top-0 left-0 w-full h-full">
                    <div className="w-full h-full relative">
                        <div className="text-white text-3xl md:text-4xl absolute top-1/2 w-full text-center font-light" style={{ letterSpacing: "10px" }}>Knowledge Center Cameroon</div>
                    </div>
                </div>

            </div>

            <div className='grid md:grid-cols-2 bg-black text-white '>
                <div className='py-10 p-4 md:p-10 relative'>
                    <div className='lg:-translate-y-1/2 lg:top-1/2 lg:absolute '>
                        <div>
                            <div className='text-3xl font-semibold pb-3 space-x-3'>Who we are</div>
                            <div className='h-2 w-20 bg-red-500 text-center'></div>
                        </div>
                        <div className='pr-10 py-10'>
                            <div className='text-lg '>
                                Knowledge Center (KC) is an educational institution that was founded in November of 2019 by a group of
                                academically high-achieving university freshmen who were passionate about helping future generations of
                                science students succeed in their academic endeavors, especially at their end-of-course GCE examinations.
                                From this small act of community service, KC has blossomed into a far bigger odyssey of scientific and
                                humanitarian engagement.
                            </div>
                            <div className='mt-10'>
                                <Link to={STUDENTLOGIN}> <button className='button rounded-md mr-8'>LogIn</button></Link>
                                <Link to={STUDENTSIGNUP}> <button className='button bg-danger rounded-md'>SignUp</button></Link>
                            </div>


                        </div>
                    </div>


                </div>

                <div className='md:text-end'>
                    <img src={groupred3} alt="" className='inline-block' />
                </div>

            </div>


            <div className='grid md:grid-cols-2 bg-black  text-white'>
                <div className='md:text-end'>
                    <img src={groupred1} alt="" className='inline-block' />
                </div>
                <div className='py-10 p-4 md:p-10 relative'>
                    <div className='lg:-translate-y-1/2 lg:top-1/2 lg:absolute '>
                        <div>
                            <div className='text-3xl font-semibold pb-3 space-x-3'> What do we do?</div>
                            <div className='h-2 w-20 bg-red-500 text-center'></div>
                        </div>
                        <div className='pr-10 py-10'>
                            <div className='text-lg '>
                                We tutor young Cameroonians, and growing scientists, unto unprecedented levels of scientific curiosity,
                                creativity, and love. We try, as best we can, while teaching them to look a little beyond the scope of
                                examinations (both in the classroom and at the GCE), and help them see the applications and manifestations
                                of the concepts they learn, which at first may seem abstract and out of reach, and how to foster creativity by
                                utilizing these concepts in solving real-world problems. And in our quest to unveil the beauty of science, we
                                do not forget to instill in them the humanitarian values which we think all men should possess, such as
                                compassion, and honesty, for without these, our scientific conquests would add up to naught.
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className="my-5">
                <LandingCarousel></LandingCarousel>
            </div>

            {/* <div className='bg-white flex mt-10'>

                <div>
                    <img src={groupred4} alt="" className='w-full' />
                </div>

                <div className='m-10 w-[70%]'>
                    <div className='font-semibold'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Maiores eveniet pariatur velit omnis et. Magnam.
                    </div>
                    <div className='h-2 w-24 bg-danger text-center mt-5'></div>
                    <div className='font-light text-sm pt-10'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni architecto adipisci,
                        maxime veniam obcaecati illo ipsa perspiciatis ab alias recusandae amet veritatis ratione laborum,
                        assumenda iste eos similique illum, nihil quam explicabo repudiandae iure dolorem. Odio temporibus aperiam ea.
                        Dolor dicta dignissimos tempore est iusto omnis quisquam aperiam unde eveniet.
                    </div>
                </div>

                <div>
                    <img src={award1} alt="" className='px-16 pt-8' />
                </div>

            </div> */}
            <div className="grid md:grid-cols-6 mt-10">
                <div></div>
                <div className="mt-10 p-5  col-span-4">
                    <div>
                        <div>
                            <div className='text-3xl font-semibold pb-3 space-x-3'>Our Activities</div>
                            <div className='h-2 w-20 bg-red-500 text-center'></div>
                        </div>
                        <div className="mt-1">
                            <div className="ml-5">
                                <div className="text-xl font-bold underline mb-2 mt-3">Tutoring</div>
                                <p className="mb-1"> Building from a rich repertoire of books and question papers, combined with novel material
                                    developed by the KC staff, we have put together a curriculum that sets students on a course to acing
                                    their GCE examinations.</p>
                                <p className="mb-1"> KC has as teachers, some of the smartest minds in the country, who teach with unbridled
                                    commitment and love.</p>
                                <p className="mb-1"> Family, epiphany, serendipity, and scientific obsession in some unknown permutation. This is who
                                    we are</p>
                                <div className="grid md:grid-cols-3 gap-4 py-10">
                                    <div><img src={class1} alt="" /></div>
                                    <div><img src={class2} alt="" /></div>
                                    <div><img src={class3} alt="" /></div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-1">
                            <div className="ml-5 ">
                                <div className="text-xl font-bold underline mb-2 mt-3">Mentorship</div>
                                <p className="mb-1">
                                    At KC, every student gets to pick out a teacher that inspires them, and whom they feel can spur them
                                    unto achieving the very best, not just in the GCE exams, but at life. We don't just think about today,
                                    or tomorrow, but of a generation.</p>
                                <div className="grid md:grid-cols-2 gap-4 py-10 md:mx-32">
                                    <div><img src={LandingImages.landing12} alt="" /></div>
                                    <div><img src={LandingImages.landing5} alt="" /></div>
                                </div>
                            </div>

                        </div>


                        <div className="mt-1">
                            <div className="ml-5 ">
                                <div className="text-xl font-bold underline mb-2 mt-3">Sports and recreation</div>
                                <p className="mb-1">
                                    Every once in a while, we seal the pages of our books and just head out into the sun. To play, to
                                    laugh, and to live.</p>
                                <div className="grid md:grid-cols-2 gap-4 py-10 md:mx-32">
                                    <div><img src={LandingImages.landing4} alt="" /></div>
                                    <div><img src={LandingImages.landing7} alt="" /></div>
                                </div>
                            </div>

                        </div>


                        <div className="mt-1">
                            <div className="ml-5 ">
                                <div className="text-xl font-bold underline mb-2 mt-3">Projects</div>
                                <p className="mb-1">
                                    KC provides a fund which students trying to build useful science projects can benefit from. We don't
                                    just end at the classroom, we are looking to the real world.</p>
                                <div className="grid md:grid-cols-2 gap-4 py-10 md:mx-32">
                                    <div><img src={LandingImages.landing1} alt="" /></div>
                                    {/* <div><img src={landing} alt="" /></div> */}
                                </div>
                            </div>

                        </div>


                        <div className="mt-1">
                            <div className="ml-5 ">
                                <div className="text-xl font-bold underline mb-2 mt-3">Competitions</div>
                                <p className="mb-1">
                                    We believe in healthy competition between students as it helps them improve upon themselves; we
                                    equally believe in rewarding effort and excellence. KC hosts an annual STEM Competition to do just
                                    that.</p>
                                {/* <div className="grid md:grid-cols-2 gap-4 py-10 md:mx-32">
                                <div><img src={group3} alt="" /></div>
                                <div><img src={group4} alt="" /></div>
                            </div> */}
                            </div>

                        </div>

                        <div className="mt-1">
                            <div className="ml-5 ">
                                <div className="text-xl font-bold underline mb-2 mt-3">Our Team</div>
                                <p className="mb-1">
                                    With a vibrant and committed staff body, we aim for nothing less than the best</p>
                                <div className="grid md:grid-cols-2 gap-4 py-10 md:mx-32">
                                    <div><img src={LandingImages.landing8} alt="" /></div>
                                    <div><img src={LandingImages.landing6} alt="" /></div>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className='mt-10'>
                        <div className=" p-5  col-span-4">
                            <div>
                                <div className='text-3xl font-semibold pb-3 space-x-3'>Our values</div>
                                <div className='h-2 w-20 bg-red-500 text-center'></div>
                            </div>
                            <div className="mt-1">
                                <div className="ml-5">
                                    {/* <div className="text-xl font-bold underline mb-2 mt-3">Tutoring</div> */}
                                    <p className="mb-1">
                                        At KC, we are not just a group of teachers and students; we are a family. And in that order, we have family, friendship,
                                        scientific obsession, resilience, hard work, humility, honesty, kindness, and love as our core values</p>
                                    <div className="grid md:grid-cols-3 gap-4 py-10">
                                        <div><img src={group2} alt="" /></div>
                                        <div><img src={group1} alt="" /></div>
                                        <div><img src={groupred2} alt="" /></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='mt-10'>
                        <div className=" p-5  col-span-4">
                            <div>
                                <div className='text-3xl font-semibold pb-3 space-x-3'>Upcoming Events</div>
                                <div className='h-2 w-20 bg-red-500 text-center'></div>
                            </div>
                            <div className="mt-1">
                                <div className="ml-5">
                                    <div className="text-xl font-bold underline mb-2 mt-3">STEM Competition</div>
                                    <p className="mb-3">
                                        The KC STEM (Science, Technology, Engineering, and Mathematics) Competition, whose first edition took place in December of 2021, is an annual national (Cameroon) scientific contest organized by Knowledge Center (KC) in an effort to foster scientific thinking in students.
                                    </p>
                                    <p className="mb-3">
                                        We challenge participants with thought-provoking questions which force them to think beyond the ordinary student’s mental reach, and, perhaps for the first time, unlock the floodgates of creativity and critical thinking.
                                    </p>
                                    <p className="mb-3">
                                        At the end of this annual contest (and here, since the competition is only a year old, we say “annual” in the confident hope that the competition will carry on in perpetuity), after having evaluated their performances, we reward the best students for their scientific enthusiasm and mastery; with this, we pass across a simple resounding message: “Science is so important in our world, and we must keep young minds ablaze with love for it.”
                                    </p>
                                    <div className="grid md:grid-cols-3 gap-4 py-10">
                                        <div><img src={flyer} alt="" /></div>
                                        <div><img src={compete} alt="" /></div>
                                        <div><img src={award1} alt="" /></div>
                                    </div>
                                    <div className="py-3">
                                        Register using the link below <a className='underline text-danger' href="https://knowledgecentercmr.web.app/stem-competition">https://knowledgecentercmr.web.app/stem-competition</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='mt-10'>
                        <div className=" p-5  col-span-4">
                            <div className='mb-3'>
                                <div className='text-3xl font-semibold pb-3 space-x-3'> Donations and sponsorships</div>
                                <div className='h-2 w-20 bg-red-500 text-center'></div>
                            </div>
                            <div className="mt-1">
                                <div className="ml-5">
                                    <p className="mb-1">
                                        The staff at KC put in their all to ensure that we produce well-rounded human beings who can drive positive change
                                        in our society. If you would like to support financially in any way, we would be grateful. </p>
                                    <Link to={LANDINGDONATION}> <button className="bg-blue-500 button mt-4">Donate</button></Link>
                                </div>

                            </div>
                        </div>
                    </div>










                </div>
                <div></div>
            </div>





            {/* <div className='bg-light'>
                <div className='p-32 text-center'>
                    <div className='font-semibold'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum, veritatis. Commodi dolor,
                        reprehenderit fugiat reiciendis enim velit consequatur iusto minima assumenda culpa atque esse animi
                        nam libero veritatis doloribus dolore in nihil necessitatibus a alias harum aspernatur exercitationem sit!
                        Saepe eligendi accusamus magnam labore quaerat, ut similique eveniet consequatur expedita.
                    </div>
                    <div className='h-2 w-24 bg-danger mt-8 m-auto'></div>
                </div>
            </div> */}

            {/* <div className='bg-orange grid md:grid-cols-3 gap-9 p-20'>
                <div>
                    <div className='text-3xl font-semibold'>The Scout Scope</div>
                    <div><img src={award1} alt="" className='my-16' /></div>
                </div>

                <div className=''>
                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-blue-500 flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>1</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-danger flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>2</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-thickOrange flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>3</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                </div>

                <div className=''>
                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-gold flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>1</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-purple flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>2</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                    <div className='flex pt-5'>
                        <div className='px-5 items-center'>
                            <div className='h-16 w-16 bg-pink flex items-center justify-center text-white text-3xl font-bold rounded-[100%]'>3</div>
                        </div>

                        <div>
                            <div className='text-2xl font-semibold'>Lorem ipsum dolor sit amet.</div>
                            <div className='font-extralight text-sm pt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                        </div>
                    </div>

                </div>
            </div> */}

            {/* <div className='bg-white p-36 m-auto'>
                <div className='flex gap-28'>
                    <div>
                        <div className='flex gap-12'>
                            <div>THE WRITTEN WORD</div>
                            <div><button className='button bg-danger rounded-sm w-32 h-8 text-sm'>VIEW MORE</button></div>
                        </div>

                    </div>
                    <div>
                        INFINITY MATH
                    </div>
                </div>

                <div className='grid md:grid-cols-5 pt-10 gap-20'>
                    <div className='col-span-2'>
                        <div className='font-bold text-3xl md:p-5'>
                            INFINITE POSSIBILITIES WITH INFINITY MATH
                        </div>
                        <div className='h-2 w-24 bg-danger mt-4 md:ml-5'></div>
                    </div>

                    <div className='col-span-3 font-extralight'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus et enim non ipsa officia
                        numquam suscipit, debitis a eveniet molestias iusto accusantium facilis consectetur doloremque
                        similique repellendus facere maxime libero. Eos suscipit cumque consectetur autem eius debitis recusandae quae culpa amet,
                        voluptatibus assumenda ea deserunt exercitationem sequi sit nemo numquam
                        eligendi tempore voluptatum eaque inventore magni obcaecati rerum corporis? Accusantium.
                    </div>
                </div>

            </div> */}



            <div className='p-5 py-6 md:py-20 bg-gray-50'>
                <div className="text-3xl my-3">KC Blog</div>
                <div className="text-lg">
                    Read articles on our blog about a plethora of subjects, from universal gravitation, to molecular biology, to student life at KC. There's a story for every eye.
                    <br />
                    <Link to={LANDINGBLOG}>
                        <button className="btn button mt-2 bg-dark text-sm">Read More</button>
                    </Link>

                    <BlogCategories />
                </div>
            </div>

            <div className="mt-10 py-10 p-6">
                <div className="mb-4 text-2xl">Our Location</div>
                <iframe className='w-full h-[40vh] md:w-2/3 md:h-[600px] m-auto' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1026.3473510270485!2d9.27875404468537!3d4.15894437513671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1061321ffda78509%3A0xe5a0555444c7dcd3!2sSt.%20Charles%20Lwanga!5e0!3m2!1sen!2scm!4v1669442806662!5m2!1sen!2scm" loading="lazy" ></iframe>
            </div>
        </div>
    )
}

export default MainLandingPage