import { Alert, Chip, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { GiCancel } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { createBlogPost } from '../../../api/firebase/blog.api';
import { selectAdmin } from '../../../api/states/admin.state';
import { convertNameToPostId } from '../../../api/utilities/blog.utility';
import { CKEditor, ClassicEditor } from '../../../components/BlogAdmin/CKEditor.component';
import { BlogPost } from '../../../interfaces/blog.interface'

const AdminAddBlog = () => {
    const [content, setContent] = useState(" < i > Start Creating your Article < br /><br/><br/><br/><br/></i > ")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [selectedClasses, setSelectedClasses] = useState<string[]>([])
    const allClasses = ['FORM 1', "FORM 2", "FORM 3", "FORM 4", "FORM5 ", "LOWERSIXTH", "UPPERSIXTH"]
    const [displayedClasses, setDisplayedClasses] = useState(allClasses)
    const [addingSelected, setAddingSelected] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [tags, setTags] = useState<string>("")
    const admin = useSelector(selectAdmin)

    function removeSelected(index: number) {
        setSelectedClasses([...selectedClasses.filter((c, i) => i != index)])
    }

    function searchForText(text: string) {
        setAddingSelected(true)
        if (!text) {
            setDisplayedClasses([])
        }
        setDisplayedClasses(allClasses.filter((cls, i) => {
            return cls.toLowerCase().includes(text.toLowerCase())
        }))

    }

    async function uploadPost() {
        if (!admin) {
            setError("Login first to post blog")
            return;
        }
        window.scrollTo(0, 0)
        setError("")
        setSuccess('')

        if (!title) {
            setError("Please provide a title for this post");
            return false
        }
        if (!description) {
            setError("Please provide a description for this post");
            return false
        }
        if (!content) {
            setError("Please provide a content for this post");
            return false
        }

        if (!image) {
            setError("Please provide a image url for this post");
            return false
        }
        setLoading(true)

        const postData: BlogPost = {
            title,
            description,
            image,
            author: admin!,
            content,
            forms: selectedClasses as ('FORM 1')[],
            id: convertNameToPostId(title),
            tags: tags.split(","),
            timestamp: Date.now(),
            reaction: {
                comments: [],
                likes: [],
                dislikes: []
            }
        }
        console.log(postData)

        try {

            await createBlogPost(postData)
            setSuccess("Successfully created blog post")

        } catch (err: any) {
            setError(err?.message)
        }
        setLoading(false)




    }


    return (
        <div className='p-3'>
            <div className="grid grid-cols-5">
                <div></div>
                <div className='col-span-3 py-8'>
                    <div className="p-2">
                        {success && <Alert severity='info'>{success}</Alert>}
                        {error && <Alert severity='error'>{error}</Alert>}
                    </div>
                    <div className='mx-10 my-6 font-light text-3xl '>Create Blog Post  {loading && <CircularProgress />}</div>

                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Title' className='p-2 rounded border outline-blue-300 w-full mb-5' />
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' className='p-2 rounded border outline-blue-300 w-full mb-5' />
                    <input type="text" value={tags} onChange={(e) => setTags(e.target.value)} placeholder='Tags' className='p-2 rounded border outline-blue-300 w-full mb-5' />
                    <input type="text" value={image} onChange={(e) => setImage(e.target.value)} placeholder='Featured Image url' className='p-2 rounded border outline-blue-300 w-full mb-5' />
                    <div className='mb-5 rounded border outline-blue-300 w-full px-4 bg-white relative'>
                        {selectedClasses.map((selected, index) => <Chip key={index} className='mt-2 mr-1  cursor-pointer' onClick={() => removeSelected(index)} sx={{ background: "#6644ff33" }} label={<span>{selected}<GiCancel className='inline-block ml-1' /> </span>} />)}
                        <input type="text" value={searchText} onChange={(e) => {
                            searchForText(e.target.value)
                            setSearchText(e.target.value)
                        }} placeholder='For Classes' className='outline-none  p-2 ' />
                        <div className="fixed ml-2  shadow bg-white z-50 w-auto">
                            {
                                addingSelected && displayedClasses.filter(c => {
                                    if (selectedClasses.find(clas => clas == c)) {
                                        return false;
                                    }
                                    return true;
                                }).map(form => {

                                    return (
                                        <div
                                            onClick={() => {
                                                setSelectedClasses([...selectedClasses, form]);
                                                setAddingSelected(false)
                                                setSearchText('')
                                            }}
                                            className="p-3 border-b px-10 cursor-pointer hover:bg-gray-200">
                                            {form}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="editor">
                        <CKEditor

                            editor={ClassicEditor}
                            data={content}
                            onReady={(editor: any) => {
                                // You can store the "(:any)" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData();
                                setContent(data)
                            }}
                            className='min-h-[60vh]'

                        />
                    </div>
                    <div className="p-8" onClick={uploadPost}>
                        <button className="rounded p-2 text-white float-right px-5 bg-danger">Save</button>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default AdminAddBlog