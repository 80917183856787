
import React, { useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import { generateSubjectDetail } from '../../api/utilities/streams.utility'
import { Subject } from '../../interfaces/competition.interface'

const SubjectSubjectsInputItem: React.FC<{ onSubjectChange: (subject: Subject) => void, subject: Subject }> = ({ subject, onSubjectChange }) => {

    const [thisSubject, setThisSubject] = useState(subject)

    function handleChange(value: string) {
        const sub = generateSubjectDetail(value);
        setThisSubject(sub)
        onSubjectChange(sub)
    }

    return (
        < >

            <input type="text" value={thisSubject.name} onChange={(e) => handleChange(e.target.value || "")} className='w-100  outline-primary border rounded px-2 py-1' />

        </>
    )
}

export default SubjectSubjectsInputItem