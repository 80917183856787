import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { Notification } from '../../interfaces/competition.interface'
import { Message } from '../../interfaces/notification.interface'

// Define a type for the slice state
interface StudentNotificationState {
    value: Message[]
}

// Define the initial state using that type
const initialState: StudentNotificationState = {
    value: [],
}

export const studentNotificationsSlice = createSlice({
    name: 'studentNotifications',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        updateStudentNotifications: (state, action: PayloadAction<Message[]>) => {
            state.value = action.payload
            console.log("PAYLOAD", action.payload)
            localStorage.setItem('studentNotifications', JSON.stringify(action.payload))
        },
    },
})

export const { updateStudentNotifications } = studentNotificationsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectStudentNotification = (state: RootState) => state.studentNotifications.value

export default studentNotificationsSlice.reducer