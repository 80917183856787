import React, { useState } from 'react'
import AdminHeader from '../Header/AdminHeader.component'
import Header from '../Header/AdminHeader.component'
import LandingHeader from '../Header/LandingHeader.component'
import StudentHeader from '../Header/StudentHeader.component'
import LandingFooter from '../LandingFooter/LandingFooter.component'
import Menu from '../Menu/Menu.component'

const StudentLayout: React.FC<{ Page: React.FC<{}> }> = ({ Page }) => {

    const [showMenu, setShowMenu] = useState(false)

    function toggleMenu() {
        setShowMenu(!showMenu)
    }

    return (
        <div className='sm:w-full  overflow-hidden'>
            <div className=' relative '>
                <StudentHeader toggleMenu={toggleMenu} />
                <div className="h-[50px] md:h-[74px]"></div>
                <Page />
                {/* {showMenu && <div onClick={toggleMenu} className='absolute top-0 z-50 bg-dark bg-opacity-50 h-full w-full'>
                    <div className="relative">
                        <Menu toggleMenu={toggleMenu} />
                    </div>
                </div>} */}
                <LandingFooter />
            </div>
        </div>

    )
}

export default StudentLayout

