import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { Student } from '../../interfaces/auth.interface'

// Define a type for the slice state
interface StudentsState {
    value: Student[]
}

// Define the initial state using that type
const initialState: StudentsState = {
    value: [],
}

export const studentsState = createSlice({
    name: 'students',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        // Use the PayloadAction type to declare the contents of `action.payload`
        updateStudents: (state, action: PayloadAction<Student[]>) => {
            state.value = action.payload.sort((s1, s2) => (s1.registeredOn > s2.registeredOn ? 1 : -1))
            localStorage.setItem('students', JSON.stringify(action.payload))
        },
    },
})

export const { updateStudents } = studentsState.actions

// Other code such as selectors can use the imported `RootState` type
export const selectStudents = (state: RootState) => state.students.value

export default studentsState.reducer