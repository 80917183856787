import React from 'react'
import { Link } from 'react-router-dom'
import coverImage from '../../../assets/images/COVER IMAGE.png'
import { STUDENTLOGIN, STUDENTSIGNUP } from '../../../constants/routes.constant'
import group3 from '../../../assets/KC pictures/group3.jpg'
import class1 from '../../../assets/KC pictures/class1.jpg'
import group1 from '../../../assets/KC pictures/group1.jpg'
import circleFrame from '../../../assets/images/circleFrame.png'
import squareFrame from '../../../assets/images/squareFrame.png'
import Tframe from '../../../assets/images/Tframe.png'
import { TextField } from '@mui/material'
import { SiFacebook } from 'react-icons/si'
import { FaTwitterSquare } from 'react-icons/fa'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FiMail } from 'react-icons/fi'
import { FaGooglePlusSquare } from 'react-icons/fa'


const LandingAbout: React.FC = () => (
  <div className='p-5 bg-white'>
    <div className='grid md:grid-cols-2 p-5'>
      <div className='mt-8 text-left mx-8'>
        <p className='text-4xl font-bold tracking-wide mr-28 mb-4'>About Knowledge Center</p>
        <p>Take a look</p>


        <div>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Soluta explicabo pariatur natus omnis magni suscipit laudantium?
            Assumenda id facere eum repudiandae molestias ea delectus,
            autem esse similique. Mollitia, laudantium alias.
          </p>
          <p className='mt-10'> Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            A incidunt hic neque placeat, delectus porro sequi praesentium dolorem odio perferendis,
            expedita voluptatibus excepturi illum molestiae deserunt quasi commodi est consequuntur quo et eaque laborum ipsa!
            Quaerat unde asperiores ad in esse voluptate sint impedit. Voluptatibus vitae quam
            delectus vel explicabo natus soluta aliquid aperiam iure ratione? Hic, distinctio rerum!
            Aliquam alias delectus earum labore quo qui molestias omnis tempore explicabo.
          </p>

        </div>

      </div>

      <div className='m-3'>
        <img src={coverImage} alt="" />
      </div>

    </div>

    <div className='bg-bgPic md:m-14'>
      <div className='md:mx-10 grid md:grid-cols-2 md:gap-8'>

        <div>
          <img src={group3} alt="" />
        </div>

        <div className='mt-20 md:pr-24'>
          <h3 className='text-2xl font-semibold'>Text Prep:</h3>
          <h3 className='text-2xl font-semibold pt-2 pb-6'>Question the Answers</h3>
          <p className='font-light'>
            We reactivate Reasoning and work with our superhumans to pick
            apart patterns, standadized success in STEM subjects, and
            embrace language and literature by transforming the abstract to
            make them concrete: foundations of and executive skillset for the...
          </p>
          <div className='py-14'><button className='button bg-danger rounded-md'>View Tutors</button></div>
        </div>

      </div>

      <div className='p-5 grid md:grid-cols-2 md:gap-8'>

        <div className='mt-20 md:pl-24'>
          <h3 className='text-2xl font-semibold'>Master Subject Matter:</h3>
          <h3 className='text-2xl font-semibold pt-2 pb-6'>No Matter The Subject</h3>
          <p className='font-light'>
            We reactivate Reasoning and work with our superhumans to pick
            apart patterns, standadized success in STEM subjects, and
            embrace language and literature by transforming the abstract to
            make them concrete: foundations of and executive skillset for the...
          </p>
          <div className='py-14'><button className='button bg-danger rounded-md'>View Tutors</button></div>
        </div>

        <div>
          <img src={class1} alt="" />
        </div>

      </div>

      <div className='p-5 grid md:grid-cols-2 md:gap-8'>

        <div>
          <img src={group1} alt="" />
        </div>

        <div className='mt-20 md:pr-24'>
          <h3 className='text-2xl font-semibold'>Beyond the Classroom:</h3>
          <h3 className='text-2xl font-semibold pt-2 pb-6'>Study kills are Life skills</h3>
          <p className='font-light'>
            We reactivate Reasoning and work with our superhumans to pick
            apart patterns, standadized success in STEM subjects, and
            embrace language and literature by transforming the abstract to
            make them concrete: foundations of and executive skillset for the...
          </p>
          <div className='py-14'><button className='button bg-danger rounded-md'>View Tutors</button></div>
        </div>

      </div>

    </div>

    <div>
      <div>
        <div className='font-bold text-center text-3xl font-serif'> <p>Why Choose Us</p></div>
        <div className='h-3 w-24 bg-blue-500 m-auto mt-5'></div>
      </div>

      <div className='grid md:grid-cols-3 mt-20 mx-20 '>
        <div>
          <img src={circleFrame} alt="" className='' />
          <div>
            <p className='font-semibold text-2xl'>Critical Connection</p>
            <p className='text-sm font-light pr-16'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Suscipit tempore ullam dolorem praesentium quae voluptatem.</p>
          </div>
        </div>

        <div>
          <img src={squareFrame} alt="" />
          <div>
            <p className='font-semibold text-2xl'>Deiverse Dedication</p>
            <p className='text-sm font-light pr-16'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Suscipit tempore ullam dolorem praesentium quae voluptatem.</p>
          </div>
        </div>

        <div>
          <img src={Tframe} alt="" />
          <div>
            <p className='font-semibold text-2xl'>Life-long learning</p>
            <p className='text-sm font-light pr-16'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Suscipit tempore ullam dolorem praesentium quae voluptatem.</p>
          </div>
        </div>

      </div>

      <div className='text-center'>
        <button className='button bg-danger rounded-md w-36 h-8 my-16 m-auto text-sm '>LEARN MORE</button>
      </div>

    </div>


  </div>
)

export default LandingAbout