import React, { useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi'
import { HiDocumentRemove } from 'react-icons/hi'
import { createCompetition } from '../../../api/firebase/competition.api'
import AddStreamSubjectsItem from '../../../components/Competition/AddStreamSubjectsItem.component'
import StreamsInputItem from '../../../components/Competition/StreamsInputItem.component'
import { Competition, CompetitionApplication, Stream } from '../../../interfaces/competition.interface'
import { Alert, CircularProgress, TextField } from '@mui/material'


const AddCompetionAdmin: React.FC = () => {

    const [streamsAdvanced, setStreamsAdvanced] = useState<Stream[]>([{ name: "", id: "", subjects: [] }])
    const [streamsOrdinary, setStreamsOrdinary] = useState<Stream[]>([{ name: "", id: "", subjects: [] }])
    const [compName, setCompName] = useState("")
    const [accepting, setAccepting] = useState(false);
    const [date, setDate] = useState(new Date().toString())
    const [description, setDescription] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")


    function addStreamAdvanced() {

        setStreamsAdvanced([...streamsAdvanced, { name: "", id: "", subjects: [] }])

    }

    const removeStreamAdvanced = (index: number) => {

        setStreamsAdvanced([...streamsAdvanced.filter((stream, i) => i !== index)])

    }

    // update stream at a particular index in stream list
    const updateStreamAdvanced = (index: number, newStream: Stream) => {
        const strms = streamsAdvanced;
        strms[index] = newStream;
        setStreamsAdvanced([...strms])
    }

    function addStreamOrdinary() {

        setStreamsOrdinary([...streamsOrdinary, { name: "", id: "", subjects: [] }])

    }

    const removeStreamOrdinary = (index: number) => {

        setStreamsOrdinary([...streamsOrdinary.filter((stream, i) => i !== index)])

    }

    // update stream at a particular index in stream list
    const updateStreamOrdinary = (index: number, newStream: Stream) => {
        const strms = streamsOrdinary;
        strms[index] = newStream;
        setStreamsOrdinary([...strms])
    }

    async function submitCompetition(e: any) {
        e.preventDefault();
        console.log(streamsAdvanced)
        const competition: Competition = {
            name: compName,
            levels: [
                {
                    id: "ADVANCED",
                    name: "Advanced Level",
                    streams: streamsAdvanced.filter(stream => !!stream.name)
                },
                {
                    id: "ORDINARY",
                    name: "Ordinary Level",
                    streams: streamsOrdinary.filter(stream => !!stream.name)
                }
            ],
            description,
            timestamp: date,
            accepting
        }

        setError("")
        setSuccess("")
        setLoading(true)
        window.scroll(0, 0)

        try {

            await createCompetition(competition)
            setSuccess("Successfully created competition !")

        } catch (err: any) {

            if (err?.message) {
                setError(err.message)
            } else {
                setError(err.toString())
            }

        }
        setLoading(false)
    }



    return (
        <div className='p-5'>
            <div className="grid md:grid-cols-5">
                <div className='col-span-1'></div>
                <div className="col-span-3">
                    <form onSubmit={submitCompetition}>
                        <div className="py-7 text-2xl mb-6">
                            Add a new Competition
                        </div>
                        <div className="p-3">
                            {success && <Alert severity='info'>{success}</Alert>}
                            {error && <Alert severity='error'>{error}</Alert>}
                        </div>
                        <div className='mb-5'>
                            <div className='mb-2'>Competition Name  {loading && <CircularProgress />} </div>
                            <div ><input disabled={loading} value={compName} onChange={(e) => setCompName(e.target.value)} required type="text" className='w-full outline-primary border rounded px-2 py-1' /></div>
                        </div>
                        <div className='mb-5'>
                            <div className='mb-3'>Accepting registration</div>
                            <div><select disabled={loading} required name="" value={accepting ? "yes" : "no"} onChange={(e) => setAccepting(e.target.value == "yes" ? true : false)} id="" className='w-full  outline-primary border rounded px-2 py-1'>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <div className='mb-3'>Date</div>
                            <div><input disabled={loading} required type="date" value={date} onChange={(e) => setDate(e.target.value)} className='w-full  outline-primary border rounded px-2 py-1' placeholder='Date' /></div>
                        </div>



                        <div className='my-8 '>
                            <div className='mb-3'>Full Description of Competition</div>
                            <div>
                                <textarea disabled={loading} value={description} onChange={(e) => setDescription(e.target.value)} required name="" id="" className='h-32 md:w-full border rounded  outline-primary px-2'></textarea>
                            </div>
                        </div>

                        <div className='mb-5 pb-4 border-b'>
                            <div className='mb-3'> Advanced Level - Add Streams </div>
                            {
                                streamsAdvanced.map((stream, index) => {
                                    return (
                                        <div className='mb-2' key={index}>
                                            <StreamsInputItem onStreamChange={(newStream) => {
                                                const strms = streamsAdvanced;
                                                strms[index] = newStream;
                                                setStreamsAdvanced([...strms]);
                                            }} stream={stream} />
                                            {index != (streamsAdvanced.length - 1) && <button onClick={() => removeStreamAdvanced(index)} className="p-2 border rounded ml-2 bg-white">
                                                <HiDocumentRemove />
                                            </button>}
                                            {index == (streamsAdvanced.length - 1) && <button onClick={() => addStreamAdvanced()} className="p-2 border rounded ml-2 bg-white">
                                                <BiAddToQueue />
                                            </button>}
                                            {
                                                <div className='mb-5 mt-4'>
                                                    <div className='mb-3'></div>

                                                    {!!stream.id && <div className='p-2 pl-10'>
                                                        <div className="py-2 capitalize">Add subjects for Stream - {stream.name}</div>
                                                        <AddStreamSubjectsItem onStreamChanged={(stream) => { updateStreamAdvanced(index, stream) }} stream={stream} />
                                                    </div>}

                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>



                        <div className='mb-5 pb-4 border-b'>
                            <div className='mb-3'> Ordinary Level - Add Streams </div>
                            {
                                streamsOrdinary.map((stream, index) => {
                                    return (
                                        <div className='mb-2' key={index}>
                                            <StreamsInputItem onStreamChange={(newStream) => {
                                                const strms = streamsOrdinary;
                                                strms[index] = newStream;
                                                setStreamsOrdinary([...strms]);
                                            }} stream={stream} />
                                            {index != (streamsOrdinary.length - 1) && <button onClick={() => removeStreamOrdinary(index)} className="p-2 border rounded ml-2 bg-white">
                                                <HiDocumentRemove />
                                            </button>}
                                            {index == (streamsOrdinary.length - 1) && <button onClick={() => addStreamOrdinary()} className="p-2 border rounded ml-2 bg-white">
                                                <BiAddToQueue />
                                            </button>}
                                            {
                                                <div className='mb-5 mt-4'>
                                                    <div className='mb-3'></div>

                                                    {!!stream.id && <div className='p-2 pl-10'>
                                                        <div className="py-2 capitalize">Add subjects for Stream - {stream.name}</div>
                                                        <AddStreamSubjectsItem onStreamChanged={(stream) => { updateStreamOrdinary(index, stream) }} stream={stream} />
                                                    </div>}

                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>



                        <div className="h-10"></div>
                        <div>
                            <button type='submit' disabled={loading} className='button float-right '>Save</button>
                        </div>
                    </form>
                </div>

                <div className='col-span-1'></div>
            </div>
        </div >


    )
}

export default AddCompetionAdmin