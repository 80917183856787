import React from 'react'
import { Link } from 'react-router-dom'
import AdminBlogCategories from '../../../components/BlogAdmin/AdminBlogCategories.component'
import { ADMINADDBLOG } from '../../../constants/routes.constant'

const BlogAdmin = () => {
  return (
    <div className=''>
      <div className="p-5">
        <Link to={ADMINADDBLOG}>
          <button className="p-2 rounded bg-gradient-to-tr from-blue-400 to-blue-600 text-white shadow float-right  px-4 ">
            ADD
          </button>
        </Link>
      </div>
      <AdminBlogCategories />
    </div>
  )
}

export default BlogAdmin