import { Chip } from '@mui/material'
import React from 'react'
import { HiDocumentDownload } from 'react-icons/hi'
import { defaultStudent } from '../../data/default.data'
import { Notification } from '../../interfaces/competition.interface'
import { Message } from '../../interfaces/notification.interface'




const NotificationCard: React.FC<{ data: Message }> = ({ data }) => {
    return (
        <div>
            <div className="p-4 border bg-white">
                <div className="text-lg">{data.title} <span className='text-sm float-right font-light'>{new Date().toLocaleDateString()},{new Date().toLocaleTimeString()}</span></div>
                <div className="pt-1 text-sm text-gray-400">
                    {data.description.split(" ").filter((word, index) => {
                        if (index <= 20) {
                            return word + " "
                        }
                        else if (index == 21) {
                            return word + "..."
                        }
                        return ""
                    }).join(" ")}

                </div>

            </div>
        </div>
    )
}

export default NotificationCard