import { Avatar, Button, Fab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BsArrowRight, BsShare } from 'react-icons/bs'
import { HiThumbDown, HiThumbUp } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchBlogById, onfetchBlogById } from '../../../api/firebase/blog.api'
import { selectStudent } from '../../../api/states/student.state'
import ReactionControls from '../../../components/BlogReactions/ReactionControls.component'
import { BlogPost } from '../../../interfaces/blog.interface'

const BlogDetail = () => {

    const param = useParams() as { id: string }
    const [loading, setLoading] = useState(false)
    const [post, setPost] = useState<BlogPost>()
    const student = useSelector(selectStudent);
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {

        if (param.id) {
            setLoading(true)
            onfetchBlogById(param.id, (res) => {
                setPost(res)
            })
        }
        return () => {

        }
    }, [param])




    return (
        <div >
            <div className="p-6  grid  grid-cols-1 md:grid-cols-6 gap-x-10 relative">
                <div className='relative'></div>
                <div className="col-span-3 relative">
                    <div className="py-4 text-3xl mb-3 font-bold">{post?.title}</div>
                    <div dangerouslySetInnerHTML={{ __html: post?.content || "" }}></div>
                    {
                        !loading && !post && <div className='text-center text-3xl mt-20 text-gray-600'>
                            NO POST WITH THAT LINK
                        </div>
                    }
                </div>
                <div className="p-3 py-7  md:col-span-1 lg:col-span-2 relative">

                    <div className="sticky top-0">
                        {post && <ReactionControls post={post}></ReactionControls>}
                        <div className="h-10"></div>

                        {
                            post?.reaction && post?.reaction?.comments.map((c, index) => {
                                if (index > 10 && !showMore) {
                                    return;
                                }
                                return (
                                    <div className="mt-3">
                                        <div className="border bg-white rounded px-2 flex">
                                            <Avatar sx={{ transform: "scale(0.7)" }} className='inline-block'>{c.user.name[0]}</Avatar>
                                            <div className='inline-block w-full bg-transparent outline-none pl-1 py-2 text-sm' >
                                                <div className="font-bold">
                                                    {c.user.name}
                                                </div>
                                                <div className='text-sm text-gray-800'>{c.message}</div>
                                                <div>
                                                    <span className="float-right text-[12px]">
                                                        {new Date(c.timestamp).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {post?.reaction && post.reaction.comments.length > 10 && <div className="m-4">
                            {showMore ? <button onClick={() => setShowMore(false)} className="text-blue-500 p-3">
                                Less
                            </button> : <button onClick={() => setShowMore(true)} className="text-blue-500 p-3">
                                Read more
                            </button>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetail