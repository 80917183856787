import { Paper, Button } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { LandingImages } from '../../assets/KC pictures'
import groupred3 from '../../assets/KC pictures/groupred3.jpg'
import groupred2 from '../../assets/KC pictures/groupred2.jpg'
import group1 from '../../assets/KC pictures/group1.jpg'

const LandingCarousel: React.FC = () => {
    return (
        <div>
            <div className="grid md:grid-cols-6 ">
                <div></div>
                <div className="mt-10 p-5  col-span-4">
                    <Carousel
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                backgroundColor: 'red',
                                borderRadius: 0
                            }
                        }}
                    >
                        <Paper>
                            <div className='relative'>
                                <img src={groupred3} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "3px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center text-xl  md:text-3xl text-white absolute">
                                    Welcome to KC
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing2} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Home where passion meets academic drive
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing1} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Inspiring a generation of creative thinkers
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={groupred2} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Family, epiphany, serendipity, and scientific obsession in some unknown permutation
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing3} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Electric and eccentric emerging scientific contributors
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing4} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    The launchpad to dreams
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={group1} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Breaking barriers, transforming lives.
                                </div>
                            </div>
                        </Paper>

                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing5} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    From KC, to the cosmos.
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing8} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                            </div>
                        </Paper>

                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing6} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                                <div style={{ letterSpacing: "2px" }} className="top-2/3 w-full font-light -translate-y-1/2 text-center md:text-3xl text-white absolute">
                                    Living free, for the love of science.
                                </div>
                            </div>
                        </Paper>
                        <Paper>
                            <div className='relative'>
                                <img src={LandingImages.landing7} className='w-full md:max-h-[600px]  object-cover object-top' alt="" />
                                <div className="h-full w-full top-0 bg-black bg-opacity-50 absolute"></div>
                            </div>
                        </Paper>




                    </Carousel>
                </div>
                <div></div>
            </div>
        </div >
    )
}

export default LandingCarousel