import { collection, deleteDoc, doc, getFirestore, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { Resource } from "../../interfaces/resource.interface";




export const createResource = (resource: Resource) => {

    const resourceRef = doc(getFirestore(), "resources", resource.id);

    return setDoc(resourceRef, resource);
}



export const deleteResource = (resourceId: string) => {

    const resourceRef = doc(getFirestore(), "resources", resourceId);

    return deleteDoc(resourceRef);
}

export const editResource = (resource: Resource, id: string) => {

    const resourceRef = doc(getFirestore(), "resources", id);

    return updateDoc(resourceRef, { ...resource, id });
}


export const getAllResources = (callBack: (resources: Resource[]) => void) => {

    const resourceRef = collection(getFirestore(), "resources");

    onSnapshot(resourceRef, (snapshot) => {
        callBack(snapshot.docs.map(doc => doc.data() as Resource))
    })

}