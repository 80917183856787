import { Card, Avatar, Chip } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getAllParticipants, getAllRegistered } from '../../../api/firebase/competition.api'
import { CompetitionApplication, Competition } from '../../../interfaces/competition.interface'

const CompetitionRegistered = () => {
    const [applications, setApplication] = useState<CompetitionApplication[]>([])
    const location = useLocation()

    useEffect(() => {

        const state = location.state as { competition: Competition }
        if (state && state.competition) {
            getRegistered(state.competition);
        }

    }, [])

    async function getRegistered(competition: Competition) {
        try {
            const applications = (await getAllParticipants(competition))
            setApplication(applications)
        } catch (err: any) {
            if (err.message) {
                console.log(err.message)
            }
        }
    }


    return (
        <div className='p-4'>
            <div className="text-xl mb-5">Registered</div>
            <div className='p-2 text-gray border-b mb-2'>
                ADVANCED LEVEL
            </div>
            {
                applications.filter((appl) => appl.level.id == "ADVANCED").map((appl: CompetitionApplication) => {
                    return (
                        <Card className='p-3 mb-3 grid grid-cols-6 items-center'>
                            <div className="col-span-1 text-center">
                                <Avatar className='mr-4' sx={{ background: "#3b75cb", float: "left", margin: "auto" }}>{appl.student.name[0]}</Avatar>
                            </div>
                            <div className="col-span-5 grid md:grid-cols-4  md:text-center">
                                <div className="">{appl.student.name}</div>
                                <div className="text-sm">{appl.student.phone} </div>
                                <div className='text-sm'>{appl.student.email}</div>
                                <div className="text-sm"><Chip className='mr-2' color='primary' label={appl.level.stream.name}></Chip>{appl.level.stream.subjects.map((sub) => (<Chip className='mr-2' label={sub.name}></Chip>))}</div>
                            </div>

                        </Card>
                    )

                })
            }
            <div className='p-2 mt-10 text-gray border-b mb-2'>
                ORDINARY LEVEL
            </div>
            {
                applications.filter((appl) => appl.level.id == "ORDINARY").map((appl: CompetitionApplication) => {
                    return (
                        <Card className='p-3 mb-3 grid grid-cols-6 items-center'>
                            <div className="col-span-1 text-center self-center">
                                <Avatar className='mr-4' sx={{ background: "#3b75cb", float: "left", margin: "auto" }}>{appl.student.name[0]}</Avatar>
                            </div>
                            <div className="col-span-5 grid md:grid-cols-4  md:text-center">
                                <div className="">{appl.student.name}</div>
                                <div className="text-sm">{appl.student.phone} </div>
                                <div className='text-sm'>{appl.student.email}</div>
                                <div className="text-sm"><Chip className='mr-2' color='primary' label={appl.level.stream.name}></Chip>{appl.level.stream.subjects.map((sub) => (<Chip className='mr-2' label={sub.name}></Chip>))}</div>
                            </div>

                        </Card>)

                })
            }
            {
                applications.length == 0 && <div className='p-6 text-lg text-gray-500'>
                    <p className='text-center'>No registered found</p>
                </div>
            }
        </div>
    )
}

export default CompetitionRegistered