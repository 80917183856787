import { Avatar, Button } from '@mui/material'
import { signOut } from 'firebase/auth'
import React, { useState, useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../../api/firebase'
import { selectStudent, updateStudent } from '../../api/states/student.state'
import { LANDINGABOUT, LANDINGBLOG, LANDINGSTEMCOMP, STUDENTLOGIN, STUDENTNOTIFICATIONS, STUDENTPROFILE, STUDENTREGISTRATION, STUDENTRESOURCES, STUDENTSIGNUP } from '../../constants/routes.constant'
import "./Header.css"

const StudentHeader: React.FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
    const student = useSelector(selectStudent)
    const [showMenu, setShowMenu] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const headerColor = "text-white bg-black bg-opacity-90" //: "text-black bg-white";

    useEffect(() => {

        if (location) {
            setShowMenu(false)
        }

    }, [location])


    async function logout() {


        localStorage.remove("admin")
        localStorage.remove("student")
        dispatch(updateStudent(undefined))
        navigate("/", { replace: true })

        // await signOut(auth)


    }
    return (
        <div className={`p-3 ${headerColor} fixed z-50 top-0 w-full`}>
            <div className="grid lg:grid-cols-4 xl:grid-cols-6 gap-y-4 relative items-center">
                <div className='lg:col-span-1 xl:col-span-2'><span className="text-lg"><span className='text-blue-500'>K</span><span className='text-danger'>C</span></span> Knowledge Center
                    <Button onClick={() => setShowMenu(!showMenu)} className="inline-block float-right visible md:hidden p-2 py-4">
                        <GiHamburgerMenu className='mobile' />
                    </Button>
                </div>
                <div className="hidden web capitalize text-sm sm:grid grid-cols-8 lg:col-span-3 xl:col-span-4 items-center justify-center text-center ">
                    <StudentMenuLink url={"/home"} title={'Home'} matchParam="home" />
                    <StudentMenuLink url={LANDINGABOUT} title={'About Us'} matchParam="about" />
                    <StudentMenuLink url={LANDINGSTEMCOMP} title={'Competition'} matchParam="competition" />
                    <StudentMenuLink url={LANDINGBLOG} title={'Blog'} matchParam="blog" />
                    {student && <StudentMenuLink url={STUDENTRESOURCES} title={'Resources'} matchParam="resources" />}
                    {student && <StudentMenuLink url={STUDENTNOTIFICATIONS} title={'Notifications'} matchParam="notifications" />}
                    {student && <StudentMenuLink url={STUDENTPROFILE} title={'Profile'} matchParam="profile" />}
                    <div className='grid grid-cols-2 col-span-1 text-center' onClick={logout}>
                        {
                            student && <Avatar className='m-auto' sx={{ background: "#3b75cb" }}>{student?.name[0]}</Avatar>
                        }
                        {
                            !student &&
                            <StudentMenuLink url={STUDENTSIGNUP} title={'Signup'} matchParam="signup" />
                        }
                        {!student && <StudentMenuLink url={STUDENTLOGIN} title={'Login'} matchParam="login" />}
                    </div>
                </div>
            </div>
            <div className="relative max-w-screen overflow-hidden">
                <div className={"fixed top-0 p-4 mobile z-50 shadow-lg bg-transparent-50 w-full backdrop-blur-md transition-all " + (showMenu ? "tranlate-x-0 z-40 opacity-100" : "translate-x-[100vh] z-0 opacity-0 ") + " " + headerColor}>
                    <div className="p-4 mb-8">
                        <button onClick={() => setShowMenu(false)} className='float-left inline-block text-[20px] text-blue-400'>
                            <AiOutlineCloseCircle />
                        </button>
                        {
                            student && <Avatar className='float-right' sx={{ background: "#3b75cb" }}>{student?.name[0]}</Avatar>
                        }
                    </div>
                    <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={"/home"} title={'Home'} matchParam="home" />
                    </div>
                    <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={LANDINGABOUT} title={'About Us'} matchParam="about" />
                    </div>


                    <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={LANDINGSTEMCOMP} title={'Competition'} matchParam="competition" />
                    </div>
                    <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={LANDINGBLOG} title={'Blog'} matchParam="blog" />
                    </div>
                    {student && <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={STUDENTRESOURCES} title={'Resources'} matchParam="resources" />

                    </div>
                    }
                    {
                        !student && <div className='border-b-[1px] border-gray-600 '>
                            <StudentMenuLink url={STUDENTLOGIN} title={'Login'} matchParam="login" />
                        </div>
                    }
                    {
                        !student && <div className='border-b-[1px] border-gray-600 '>
                            <StudentMenuLink url={STUDENTSIGNUP} title={'Signup'} matchParam="signup" />
                        </div>
                    }
                    {
                        student && <div className='border-b-[1px] border-gray-600 '>
                            <StudentMenuLink url={STUDENTNOTIFICATIONS} title={'Notifications'} matchParam="notifications" />
                        </div>}
                    {student && <div className='border-b-[1px] border-gray-600 '>
                        <StudentMenuLink url={STUDENTPROFILE} title={'Profile'} matchParam="profile" />
                    </div>}

                </div>
                {showMenu && <div onClick={() => setShowMenu(false)} className="fixed top-0 z-30 left-0 w-full h-full bg-opacity-50 bg-black"></div>}

            </div>

        </div>
    )
}

export default StudentHeader


function StudentMenuLink({ url, title, matchParam }: { url: string, title: string, matchParam: string }) {
    const location = useLocation()
    const isCurrentPage = location.pathname.match(matchParam)
    const isCurrentPageStyle = isCurrentPage ? 'text-danger' : "text-white"
    // headerColor = location.pathname.match("competition") ? "text-white bg-black" : "text-white bg-white";


    return (
        <Link to={url}> <div className={`col-span-1 cursor-pointer p-3 py-4 
          ${isCurrentPageStyle} hover:text-red-400`}>
            {title}
        </div>
        </Link>
    )
}
