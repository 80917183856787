import { Card } from '@mui/material'
import React from 'react'

const BankDetails = () => {
    return (
        <div className='p-5 relative min-h-screen'>

            <div className="mt-32 w-full ">
                <div className="grid md:grid-cols-3 z-20 pt-10 fixed top-20 w-full">
                    <div></div>
                    <div>
                        <Card className='p-4 '>
                            <div className='mb-10'>
                                <div className="text-xl text-gray-600 mb"> Account Number </div>
                                <div className="text-3xl font-bold">090802654 </div>
                            </div>
                            <div className='mb-10'>
                                <div className="text-xl text-gray-600 mb"> Bank </div>
                                <div className="text-3xl font-bold">Ntarikon Cooperative credit union Ltd, Buea Branch </div>
                            </div>
                        </Card>
                    </div>
                    <div></div>
                </div>
            </div>


        </div>
    )
}

export default BankDetails