import React, { useEffect, useState } from 'react'
import { BiAddToQueue } from 'react-icons/bi';
import { HiDocumentRemove } from 'react-icons/hi';
import { Stream, Subject } from '../../interfaces/competition.interface';
import StreamsInputItem from './StreamsInputItem.component';
import SubjectSubjectsInputItem from './StreamSubjectsInputItem';

const AddStreamSubjectsItem: React.FC<{ stream: Stream, onStreamChanged: (stream: Stream) => void }> = ({ stream, onStreamChanged }) => {
    const [subjects, setSubjects] = useState<Subject[]>([...stream.subjects, { name: "", id: "" }])
    function addSubject() {

        setSubjects([...subjects, { name: "", id: "" }])
        let newStream = stream;
        newStream.subjects = subjects;
        onStreamChanged(newStream);
    }

    const removeSubject = (index: number) => {

        setSubjects((subjects) => subjects.filter((stream, i) => i !== index))
        let newStream = stream;
        newStream.subjects = [...subjects.filter((stream, i) => i !== index)];
        onStreamChanged(newStream);

    }
    useEffect(() => {
        if (stream) {
            setSubjects([...stream.subjects, { name: "", id: "" }])
        }
    }, [stream])
    return (
        <div className='mb-2' >
            {
                subjects.map((subject, index) => {
                    return <div>
                        <SubjectSubjectsInputItem onSubjectChange={(newSubject) => {
                            const strms = subjects;
                            strms[index] = newSubject;
                            setSubjects(strms);
                        }} subject={subject} />
                        {
                            index != (subjects.length - 1) && <button onClick={() => removeSubject(index)} className="p-2 border rounded ml-2 bg-white">
                                <HiDocumentRemove />
                            </button>
                        }
                        {
                            index == (subjects.length - 1) && <button onClick={() => addSubject()} className="p-2 border rounded ml-2 bg-white">
                                <BiAddToQueue />
                            </button>
                        }
                    </div>
                })
            }
        </div>
    )
}

export default AddStreamSubjectsItem