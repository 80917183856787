import { Avatar, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Student } from '../../../interfaces/auth.interface'

const StudentDetail: React.FC = () => {
    const [student, setStudent] = useState<Student>()
    const location = useLocation()

    useEffect(() => {

        const state = location.state as { student: Student }
        if (state?.student) {
            setStudent(state.student)
        }

    }, [])

    return (
        <div className="p-5 min-h-screen bg-white">
            <div className='grid md:grid-cols-5 items-center  gap-y-5'>
                <div className='md:col-span-1 ml-5'>
                    <Avatar className='text-3xl md:m-auto' sx={{ fontSize: "30px", width: "30px", height: "30px", padding: "20px", background: "rgb(59 117 203)" }}  >{student?.name[0]}</Avatar>
                </div>
                <div className='md:col-span-4'>
                    <h3 className='font-semibold'>{student?.name}</h3>
                    <p>{student?.form}</p>
                    <a href={`mailto:${student?.email}`} className='hover:text-primary'>{student?.email}</a>
                    <p>{student?.phone}</p>
                    <p>{student?.city}</p>
                </div>
            </div>

            <div className='mt-14 '>
                <Button variant='contained' sx={{ marginRight: "10px" }}>Message</Button>
                <Button variant='contained' color='error' >Ban {student?.name.split(" ")[0]}</Button>
            </div>
        </div>
    )
}

export default StudentDetail