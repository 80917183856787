import { Alert, CircularProgress, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { info } from 'console'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { createStudentAccount } from '../../../api/firebase/auth.api'
import bgImage from '../../../assets/images/student.jpeg'
import { STUDENTLOGIN, STUDENTREGISTRATION } from '../../../constants/routes.constant'
import { defaultClasses, defaultStudent } from '../../../data/default.data'
import { useDispatch, useSelector } from 'react-redux'
import { updateStudent } from '../../../api/states/student.state'

const Signup: React.FC = () => {

    const [student, setStudent] = useState(defaultStudent)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    async function onSubmit(e: any) {
        e.preventDefault()
        console.log(student)
        window.scrollTo(0, 0)
        setLoading(true)
        setError("")
        setSuccess('')
        const redirect = localStorage.getItem("redirect")

        try {

            const result = await createStudentAccount(student)
            console.log(result)
            setSuccess("successfully created your account")
            dispatch(updateStudent(student))
            localStorage.removeItem("redirect")
            redirect ? navigate(redirect) : navigate("/")

        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
        setLoading(false)
    }

    return (
        <div className='p-5 min-h-screen' >
            <div className='grid lg:grid-cols-3'>
                <div className='h-0 md:h-auto'>

                </div>
                <div className='col-span-1'>
                    {success && <Alert severity='info'>{success}</Alert>}
                    {error && <Alert severity='error'>{error}</Alert>}
                    <form onSubmit={onSubmit} action="">
                        <div className='mx-10 my-14 font-light text-3xl '>Create your Account {loading && <CircularProgress />}</div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.name} onChange={(e) => setStudent({ ...student, name: e.target.value })} className='w-full' id="outlined-basic" label="Names" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.age} onChange={(e) => setStudent({ ...student, age: e.target.value })} className='w-full' id="outlined-basic" label="Age" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <InputLabel className='mb-2'>Form / Class</InputLabel>
                            <Select
                                required
                                className='w-full'
                                label="Level"
                                value={student.form} onChange={(e) => setStudent({ ...student, form: (e.target.value) })}
                            >
                                {
                                    defaultClasses.map((form, index) => {
                                        return (
                                            <MenuItem value={form} key={index} className='capitalize'>{form}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </div>

                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.school} onChange={(e) => setStudent({ ...student, school: e.target.value })} className='w-full' id="outlined-basic" label="School" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.city} onChange={(e) => setStudent({ ...student, city: e.target.value })} className='w-full' id="outlined-basic" label="Town or City" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.phone} onChange={(e) => setStudent({ ...student, phone: e.target.value })} className='w-full' id="outlined-basic" label="Phone Number" variant="outlined" />
                        </div>
                        <div className='mb-6 w-full'>
                            <TextField required disabled={loading} value={student.email} onChange={(e) => setStudent({ ...student, email: e.target.value })} className='w-full' id="outlined-basic" label="Email" variant="outlined" />
                        </div>
                        <div className=''>
                            <TextField required disabled={loading} value={student.password} onChange={(e) => setStudent({ ...student, password: e.target.value })} className='w-full' id="outlined-basic" label="Password" type={'password'} variant="outlined" />
                        </div>

                        <div className={loading ? "opacity-50" : "opacity-100"}>
                            <button disabled={loading} type='submit' className='button rounded-md my-14 w-full'>Sign up </button>
                        </div>
                    </form>
                    <div className="h-10"></div>
                    <div className="py-6 text-center">
                        <Link to={STUDENTLOGIN} className=""><label className="text-sm font-light cursor-pointer underline text-blue-500">I already have an account</label></Link>
                    </div>
                </div>
                <div></div>
            </div>

        </div>
    )
}

export default Signup