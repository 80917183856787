import { collection, doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { Message } from "../../interfaces/notification.interface";



export async function sendPrivateMessage(notification: Message) {

    if (notification.channel != "PRIVATE") {
        const error = new Error();
        error.message = "This is not a private notification";
        throw error;
    }
    if (!notification.receivers || notification.receivers?.length <= 0) {
        const error = new Error();
        error.message = "Not Enough receivers for the notification";
        throw error;
    }

    for (let i = 0; i < notification.receivers!.length; i++) {
        const receiver = notification.receivers![i];
        const messageRef = doc(getFirestore(), "channels/" + receiver + "/messages", notification.id);

        const channelRef = doc(getFirestore(), "channels", receiver)
        await setDoc(channelRef, { id: receiver })
        await setDoc(messageRef, notification)


    }

}


export async function sendPublicMessage(notification: Message) {

    if (notification.channel != "PUBLIC") {
        const error = new Error();
        error.message = "This is not a public notification";
        throw error;
    }
    if (!notification.forms || notification.forms?.length <= 0) {
        const error = new Error();
        error.message = "Not Enough forms for the notification";
        throw error;
    }

    for (let i = 0; i < notification.forms!.length; i++) {
        const form = notification.forms![i];
        const messageRef = doc(getFirestore(), "channels/" + form + "/messages", notification.id);
        const channelRef = doc(getFirestore(), "channels", form)

        await setDoc(channelRef, { id: form })
        await setDoc(messageRef, notification)


    }

}


export async function getAllMessageChannels(onSuccess: (result: { id: string }[]) => void) {
    const channelsRef = collection(getFirestore(), "channels")

    onSnapshot(channelsRef, (snapshot) => {
        console.log(snapshot.docs.map(doc => ({ id: doc.id })), "CHANNELS")
        onSuccess(snapshot.docs.map(doc => ({ id: doc.id })))
    })


}

export async function getMessagesForChannel(channel: string, onSuccess: (messages: Message[]) => void) {

    const messagesRef = collection(getFirestore(), "channels/" + channel + "/messages")

    onSnapshot(messagesRef, (snapshot) => {
        onSuccess(snapshot.docs.map(doc => (doc.data() as Message)))
    })
}


export function getStudentsMessages(email: string, onSuccess: (messages: Message[]) => void) {
    const messagesRef = collection(getFirestore(), "channels/" + email + "/messages")

    onSnapshot(messagesRef, (snapshot) => {
        onSuccess(snapshot.docs.map(doc => (doc.data() as Message)))
    })
}