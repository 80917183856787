import React from 'react'
import { BiSearch } from 'react-icons/bi'

const Searchbar: React.FC<{ text?: string, onChange?: (text: string) => void }> = ({ text, onChange }) => {
    return (
        <div className='px-2 border rounded flex'>
            <BiSearch className='inline-block float-left text-[20px] mt-3' />
            <input type="text" value={text} onChange={(e) => onChange && onChange(e.target.value)} className='flex-auto p-2 inline-block outline-none placeholder:text-gray placeholder:font-light' placeholder='search' />
        </div>
    )
}

export default Searchbar