export const LANDING = '/';
export const ABOUT = '/about';
export const COMPETITIONADMIN = '/admin/competition';
export const COMPETITIONDETAIL = '/admin/competition/detail';
export const STATISTICS = '/admin/statistics';
export const ADMINRESOURCES = '/admin/resources';
export const ADMINADDCOMPETITION = '/admin/competition/add';
export const ADMINSTUDENTS = '/admin/students';
export const ADMINPROFILE = '/admin/profile';
export const ADMINBLOG = '/admin/blog';
export const ADMINSTUDENTDETAIL = '/admin/student/detail';
export const ADMINADDBLOG = '/admin/blog/add'
export const ADMINEDITBLOG = '/admin/blog/edit'
export const ADMINEDITRESOURCE = '/admin/resource/edit'
export const ADMINNOTIFICATIONS = '/admin/notifications'
export const ADMINNCOMPOSENOTIF = '/admin/notifications/new'
export const ADMINSTAFFS = '/admin/staffs'



// landing
export const LANDINGABOUT = '/about-kc'
export const LANDINGSTEMCOMP = '/stem-competition'
export const LANDINGBLOG = '/blog'
export const LANDINGBLOGDETAIL = '/blog/:id'
export const LANDINGDONATION = '/donations'
export const LANDINGBANKDETAILS = '/donations/details'



//students
export const STUDENTSIGNUP = '/students/signup'
export const STUDENTLOGIN = '/students/login'
export const STUDENTREGISTRATION = '/students/register'
export const STUDENTNOTIFICATIONS = '/students/notifications'
export const STUDENTNOTIFICATIONDETAIL = '/students/notifications/detail'
export const STUDENTPROFILE = '/students/profile'
export const STUDENTRESOURCES = '/students/resources'
