import { MenuItem, Select, selectClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllResources } from '../../../api/firebase/resource.api'
import { selectStudent } from '../../../api/states/student.state'
import ResourceCard from '../../../components/Resources/ResourceCard.component'
import ResourceStudentCard from '../../../components/Resources/ResourceStudentCard.component'
import Searchbar from '../../../components/Searchbar/Searchbar.component'
import { STUDENTLOGIN } from '../../../constants/routes.constant'
import { defaultClasses, fileTypes } from '../../../data/default.data'
import { Resource } from '../../../interfaces/resource.interface'

const Resources = () => {
    const [resources, setResources] = useState<Resource[]>([])
    const [selectedClass, setSelectedClass] = useState("All")
    const [selectedType, setselectedType] = useState("All")
    const [displayedResources, setDisplayedResources] = useState(resources)
    const [queryText, setQueryText] = useState("")
    const student = useSelector(selectStudent)


    useEffect(() => {

        getAllResources((resources) => {
            setResources(resources)
            if (displayedResources.length <= 0) {
                setDisplayedResources(resources)
            }
        })

    }, [])


    useEffect(() => {

        let list = [];
        list = resources.filter((res) => {
            if (selectedType == "All" && selectedClass == "All") {
                return true
            }
            if (selectedType == "All") {
                return res.forms.includes(selectedClass)
            }
            if (selectedClass == "All") {
                return res.type == selectedType;
            }
            return (res.type == selectedType && res.forms.includes(selectedClass))
        })
        setDisplayedResources(list)

        return () => {

        }
    }, [selectedType, selectedClass])

    function searchText(text: string) {
        setQueryText(text)
        if (!text) {
            setDisplayedResources(resources)
        }

        setDisplayedResources(() => resources.filter(res => res.title.toLowerCase().match(text.toLowerCase())))
        setselectedType("All")
        setSelectedClass("All")
    }


    return (
        <div className='min-h-screen p-10'>
            <div className="py-4 text-4xl">
                Resources
            </div>
            {!student && <Link to={STUDENTLOGIN}><div className="mt-10">
                <button className='button btn btn-blue-500 w-auto px-4'> <BiLock className='text-[20px] mr-3 inline-block' />  Login to Access</button>
            </div></Link>}
            {student && <div className="grid  md:grid-cols-7 gap-6 items-center">
                <div>
                    <Select fullWidth value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                        {
                            [...defaultClasses, "All"].map((form, index) => {
                                return (<MenuItem value={form}>{form}</MenuItem>)
                            })
                        }
                    </Select>
                </div>
                <div >
                    <Select fullWidth value={selectedType} onChange={(e) => setselectedType(e.target.value)}>
                        {
                            [...fileTypes, "All"].map((form, index) => {
                                return (<MenuItem value={form}>{form}</MenuItem>)
                            })
                        }
                    </Select>
                </div>

                <div className='md:col-span-4'>
                    <Searchbar text={queryText} onChange={searchText} />
                </div>

            </div>}
            {student && <div className="mt-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
                    {
                        displayedResources.map((resource) => {
                            return (<ResourceStudentCard resource={resource} />)
                        })
                    }
                </div>
            </div>}
        </div>
    )
}

export default Resources