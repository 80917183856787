import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { Admin, Staff, Student } from "../../interfaces/auth.interface";
import { auth } from "."

const db = getFirestore()
const studentPath = (email: string) => doc(db, 'students/' + email)
const adminPath = (email: string) => doc(db, 'admin/' + email)



export async function createStudentAccount(student: Student): Promise<Student> {

    const exists = await verifyStudentExist(student);
    if (exists) {
        throw new Error("Student already Exists");
    }

    return await createUser(student)



}

export async function editStudentInfo(student: Student) {

    await setDoc(studentPath(student.email), student)
    return student;

}

export async function verifyStudentExist(student: Student): Promise<boolean> {

    const studentRef = studentPath(student.email)

    return getDoc(studentRef).then(res => {
        return !!res.data()
    })

}

export async function createUser(student: Student) {

    await createUserWithEmailAndPassword(auth, student.email, student.password)

    await setDoc(studentPath(student.email), student)
    return student;


}

export async function signInStudent(email: string, password: string): Promise<Student | Admin> {



    const user = await signInWithEmailAndPassword(auth, email, password)
    if (!user.user.email) {
        throw new Error("User does not exist")
    }
    const admin = (await getDoc(adminPath(user.user.email))).data() as Admin
    console.log(admin)
    if (admin) {
        return admin;
    }

    return getDoc(studentPath(email)).then((res) => res.data() as Student)


}

/**
 * Gets all the students that have accounts on the application
 * 
 * @param onSuccess - called everytime a new student account is created or deleted
 */
export async function getAllStudents(onSuccess: (student: Student[]) => void) {

    const query = collection(db, 'students')
    onSnapshot(query, (res) => {
        onSuccess(res.docs.map(doc => doc.data() as Student))
    })

}


export async function createStaff(staff: Staff) {

    const staffRef = doc(getFirestore(), "staff", staff.id);

    return setDoc(staffRef, staff)
}


export async function deleteStaff(id: string) {

    const staffRef = doc(getFirestore(), "staff", id);

    return deleteDoc(staffRef)
}


export async function getAllStaff(onSuccess: (staff: Staff[]) => void) {

    const query = collection(db, 'staff')
    onSnapshot(query, (res) => {
        onSuccess(res.docs.map(doc => doc.data() as Staff))
    })

}