import { Alert, CircularProgress, MenuItem, Select, TextField } from '@mui/material'
import { error } from 'console'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signInStudent } from '../../../api/firebase/auth.api'
import { donateForCompetition } from '../../../api/firebase/competition.api'
import { updateAdmin } from '../../../api/states/admin.state'
import { updateStudent } from '../../../api/states/student.state'
import { LANDINGBANKDETAILS, STUDENTREGISTRATION, STUDENTSIGNUP } from '../../../constants/routes.constant'
import { defaultStudent } from '../../../data/default.data'
import { Admin, Student } from '../../../interfaces/auth.interface'
import * as uuid from "uuid"
import { Donation as IDonation } from '../../../interfaces/competition.interface'

const Donation: React.FC = () => {
    const [donorName, setDonorName] = useState("")
    const [donorContactName, setDonorContactName] = useState("")
    const [donorAmount, setDonorAmount] = useState(0)
    const [donorEmail, setDonorEmail] = useState("")
    const [donorPhone, setDonorPhone] = useState("")
    const [donorMethod, setDonorMethod] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    async function onSubmit(e: any) {
        e.preventDefault()
        window.scrollTo(0, 0)
        setLoading(true)
        setError("")
        setSuccess('')
        try {
            const donation: IDonation = { name: donorName, amount: donorAmount, ref: uuid.v4(), contactEmail: donorEmail, contactPhone: donorPhone, contactName: donorContactName, method: donorMethod }
            const result = await donateForCompetition(donation);

            if (donorMethod == "mobile-money") {
                const a = document.createElement('a')
                a.href = `https://zitopay.africa/sci/?currency=XAF&amount=${donorAmount}&receiver=obendesmond&memo=donation&ref=${donation.ref}notification_url=https%3A%2F%2Fpayment-server-ny0x.onrender.com/kc/payment`
                a.click()
            } else {
                navigate(LANDINGBANKDETAILS)
            }


            console.log(result)
            setSuccess("Donation recorded successfully")


        } catch (err: any) {
            if (typeof err == "string") {
                setError(err)
            } else {
                setError(err.message)
            }
        }
        setLoading(false)
    }
    return (
        <div className='p-5'>
            <div className="p-4 text-3xl font-bold">
                KC NATIONAL STEM COMPETITION SPONSORSHIP/DONATION FORM
            </div>
            <div>
                <div className="p-3">
                    Thank you for considering to support the civic scientific work initiated by Knowledge Center, KC, in this year’s edition of the National STEM competition!

                </div>
                <div className='p-3n mt-8'>
                    <div className="2xl">

                    </div>
                    <div className='grid sm:grid-cols-3'>
                        <div className='h-0 md:h-auto'>

                        </div>
                        <div className='col-span-1'>
                            <div className="p-3">
                                {success && <Alert severity='info'>{success}</Alert>}
                                {error && <Alert severity='error'>{error}</Alert>}
                            </div>
                            <div className='mx-10 my-14 font-light text-3xl '>Donation Form  {loading && <CircularProgress />}</div>

                            <form onSubmit={onSubmit} action="">
                                <div className='mb-12 w-full'>
                                    <TextField required disabled={loading} value={donorName} onChange={(e) => setDonorName(e.target.value)} className='w-full' id="outlined-basic" label="Sponsor Full Names" variant="outlined" />
                                </div>
                                <div className='mb-12 w-full'>
                                    <TextField disabled={loading} value={donorContactName} onChange={(e) => setDonorContactName(e.target.value)} className='w-full' id="outlined-basic" label="Contact Person's Names" variant="outlined" />
                                </div>
                                <div className='mb-12 w-full'>
                                    <TextField disabled={loading} value={donorEmail} type='email' onChange={(e) => setDonorEmail(e.target.value)} className='w-full' id="outlined-basic" label="Email" variant="outlined" />
                                </div>
                                <div className='mb-12 w-full'>
                                    <TextField disabled={loading} value={donorPhone} type='tel' onChange={(e) => setDonorPhone(e.target.value)} className='w-full' id="outlined-basic" label="Phone Number" variant="outlined" />
                                </div>
                                <div className='mb-12'>
                                    <TextField disabled={loading} value={donorAmount || 0} onChange={(e) => setDonorAmount(+e.target.value)} className='w-full' id="outlined-basic" label="Amount to Donate" type={'text'} variant="outlined" />
                                </div>
                                <div className="mb-12">
                                    <div className=" text-md mb-3 text-gray-700">
                                        Donation Method
                                    </div>
                                    <Select required value={donorMethod} onChange={(e) => setDonorMethod(e.target.value)} fullWidth>
                                        <MenuItem value='mobile-money'>
                                            Mobile Money
                                        </MenuItem>
                                        <MenuItem value='bank-transfer'>
                                            Bank Transfer
                                        </MenuItem>
                                    </Select>
                                </div>
                                <div>
                                    <button disabled={loading} type='submit' className='button rounded-md my-14 w-full'>Donate</button>
                                </div>
                            </form>
                            <div className="h-6"></div>

                        </div>
                        <div></div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Donation