import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectStudents } from '../../../api/states/students.state'
import flynn from '../../../assets/images/RomeFlynn.jpeg'
import { ADMINSTUDENTDETAIL } from '../../../constants/routes.constant'

const StudentsAdmin: React.FC = () => {
  const students = useSelector(selectStudents)
  const navigate = useNavigate()

  return (
    <div className='p-5'>

      <div className='mt-10 bg-white overflow-hidden px-7 rounded-md pb-3 w-[94vw]'>
        <div className='w-[200vw] md:w-auto overflow-x-scroll'>
          <h3 className='py-3'>All Students</h3>
          <div className='mt-3 grid grid-cols-7 w- font-semibold'>
            <div className='col-span-1'>Name</div>
            <div className='col-span-2'>Email</div>
            <div className='col-span-1'>Class</div>
            <div className='col-span-1'>School</div>
            <div className='col-span-2'>Date,Time</div>
          </div>

          {
            students.map((student, index) => {
              return (
                <div onClick={() => navigate(ADMINSTUDENTDETAIL, { state: { student } })} className={'mt-3 grid gap-x-6 grid-cols-7 hover:hoveredItem p-2 ' + ((index % 2 == 0) ? "bg-gray-100" : "bg-white")}>
                  <div className='col-span-1'>{student.name}</div>
                  <div className='col-span-2'>{student.email}</div>
                  <div className='col-span-1'>{student.form}</div>
                  <div className='col-span-1'>{student.school}</div>
                  <div className='col-span-2'>{student.registeredOn}</div>
                </div>
              )
            })
          }
        </div>


      </div>


    </div>
  )
}

export default StudentsAdmin