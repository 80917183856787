import { Box, Tabs, Tab } from '@mui/material'
import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import AdminHeader from '../Header/AdminHeader.component'
import Header from '../Header/AdminHeader.component'
import Menu from '../Menu/Menu.component'
import flynn from '../../assets/images/RomeFlynn.jpeg'
import { current } from '@reduxjs/toolkit'
import CompetitionDetail from '../../screens/Admin/CompetitionAdmin/CompetitionDetail.screen'
import CompetitionRegistered from '../../screens/Admin/CompetitionAdmin/CompetitionRegistered.screen'
import CompetitionProspects from '../../screens/Admin/CompetitionAdmin/CompetitionProspects.screen'

const CompetitionDetailLayout: React.FC<{}> = () => {

    const [showMenu, setShowMenu] = useState(false)
    const [currentPage, setCurrentPage] = useState<"DETAIL" | "REGISTERED" | "PROSPECTS">("DETAIL")

    function toggleMenu() {
        setShowMenu(!showMenu)
    }

    return (
        <div className='grid lg:grid-cols-8'>
            <div className=' lg:col-span-2 h-0 z-10 lg:h-full'>
                <Menu toggleMenu={toggleMenu} />
            </div>
            <div className='bg-light col-span-8 z-40 min-h-screen lg:col-span-6 relative '>
                <CompetitionLayHeader toggleMenu={toggleMenu} />
                <div className=' sticky top-0 z-40'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'white', textAlign: 'center' }}>
                        <Tabs sx={{ margin: 'auto', }} centered value={currentPage} onChange={(e, value) => setCurrentPage(value as "DETAIL")} aria-label="basic tabs example">
                            <Tab value={"DETAIL"} label="Detail" />
                            <Tab value={"REGISTERED"} label="Registered" />
                            <Tab value={"PROSPECTS"} label="Prospects" />
                        </Tabs>
                    </Box>
                </div>

                {showMenu && <div onClick={toggleMenu} className='absolute top-0 z-50 bg-dark bg-opacity-50 h-full w-full'>
                    <div className="relative">
                        <Menu toggleMenu={toggleMenu} />
                    </div>
                </div>}
                {
                    currentPage == "DETAIL" && <CompetitionDetail />
                }
                {
                    currentPage == "REGISTERED" && <CompetitionRegistered />
                }
                {
                    currentPage == "PROSPECTS" && <CompetitionProspects />
                }

            </div>
        </div>

    )
}

export default CompetitionDetailLayout












export const CompetitionLayHeader: React.FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
    return (
        <div className='grid grid-cols-3 p-2  pb-3 bg-white relative' >
            <div className='col-span-2 md:col-span-2'>
                <GiHamburgerMenu onClick={toggleMenu} className='float-left mt-1 mr-2 inline-block lg:hidden' />
                <div className='font-bold'>Welcome, Beltus</div>
                <div className='text-gray2 w-0 sm:w-auto hidden sm:block'>Here's what's happening with KC today</div>
            </div>
            <div className='col-span-1 md:col-span-1 text-center'>
                <div className="p-2 border bg-light w-0 sm:w-auto hidden sm:inline-block rounded-md mt-2 relative">
                    <BiSearch className='absolute left-3 top-3 text-gray1' />
                    <input type="text" className='pl-6 outline-none bg-transparent' placeholder='Search...' />
                    <button className="bg-white absolute right-2 rounded shadow-sm p-[2px] px-3 top-[7px]">
                        Go
                    </button>
                </div>
                <img src={flynn} alt="Rome"
                    className='w-9 h-9 float-right mt-3 mr-1 inline-block rounded-full object-cover' />
            </div>
        </div>
    )
}