import { Chip } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getAllResources } from '../../../api/firebase/resource.api'
import { selectCompetition } from '../../../api/states/competitions.state'
import { selectStudents } from '../../../api/states/students.state'
import { ADMINRESOURCES, ADMINSTUDENTDETAIL, ADMINSTUDENTS, COMPETITIONADMIN } from '../../../constants/routes.constant'
import { Resource } from '../../../interfaces/resource.interface'

const Landing: React.FC = () => {
    const students = useSelector(selectStudents)
    const competitions = useSelector(selectCompetition)
    const navigate = useNavigate()
    const [resources, setResources] = useState<Resource[]>([])

    useEffect(() => {

        getAllResources((resources) => setResources(resources))
        axios.get("https://payment-backend-desmond.up.railway.app/kc/payment");

    }, [])

    return (
        <div>
            <div className='p-5 '>
                <div className='mt-8 grid lg:grid-cols-3 gap-10'>
                    <Link to={COMPETITIONADMIN}>
                        <div className='card hover:shadow-lg text-center'>
                            <h2 className='text-5xl font-light'>{competitions.length}</h2>
                            <h5 className='text-gray2 mt-2 '>Competitions</h5>
                        </div>
                    </Link>

                    <Link to={ADMINSTUDENTS}>
                        <div className='card hover:shadow-lg text-center'>
                            <h2 className='text-5xl font-light'>{students.length}</h2>
                            <h5 className='text-gray2 mt-2'>Students</h5>
                        </div>
                    </Link>

                    <Link to={ADMINRESOURCES}>
                        <div className='card hover:shadow-lg text-center'>
                            <h2 className='text-5xl font-light'>{resources.length}</h2>
                            <h5 className='text-gray2 mt-2'>Resources</h5>
                        </div>
                    </Link>
                </div>


                <div className='mt-10 bg-white overflow-hidden px-7 rounded-md pb-3 w-[94vw]'>
                    <div className='w-[200vw] md:w-auto overflow-x-scroll'>
                        <h3 className='py-3'>Newly Registered Students</h3>
                        <div className='mt-3 grid grid-cols-7 w- font-semibold'>
                            <div className='col-span-1'>Name</div>
                            <div className='col-span-2'>Email</div>
                            <div className='col-span-1'>Class</div>
                            <div className='col-span-1'>School</div>
                            <div className='col-span-2'>Date,Time</div>
                        </div>

                        {
                            students.map((student, index) => {
                                if (index > 10) return;
                                return (
                                    <div onClick={() => navigate(ADMINSTUDENTDETAIL, { state: { student } })} className={'mt-3 grid gap-x-6 grid-cols-7 hover:hoveredItem p-2 ' + ((index % 2 == 0) ? "bg-gray-100" : "bg-white")}>
                                        <div className='col-span-1'>{student.name}</div>
                                        <div className='col-span-2'>{student.email}</div>
                                        <div className='col-span-1'>{student.form}</div>
                                        <div className='col-span-1'>{student.school}</div>
                                        <div className='col-span-2'>{student.registeredOn}</div>
                                    </div>
                                )
                            })
                        }
                    </div>


                </div>

                <Link to={ADMINSTUDENTS}>
                    <div className='relative py-6'>
                        <div className='bg-white w-24 flex float-right p-2 rounded-md'>
                            <a href="#">See All</a>
                            <span className='pl-3 mt-1'><BsArrowRight /></span>
                        </div>
                    </div>
                </Link>

                <div>

                    <div className='mt-10 bg-white overflow-hidden w-[94vw] px-7 rounded-md pb-5'>
                        <div className='w-[200vw] md:w-auto overflow-x-scroll'>
                            <h3 className='py-3'>Upcoming Competitions</h3>
                            <div className='mt-3 grid grid-cols-7 font-semibold'>
                                <div className='col-span-1'>Name</div>
                                <div className='col-span-2'>Description </div>
                                <div className='col-span-1'>Class</div>
                                <div className='col-span-1'>Streams</div>
                                <div className='col-span-2'>Created at</div>
                            </div>
                            {
                                competitions.map((competition, index) => {
                                    if (index > 10) return;
                                    return (
                                        <div key={index} className={'mt-3 grid grid-cols-7 font-normal p-2 gap-x-10 ' + ((index % 2 == 0) ? "bg-gray-100" : "bg-white")}>
                                            <div className='col-span-1'>{competition.name}</div>
                                            <div className='col-span-2'>{competition.description} </div>
                                            <div className='col-span-1'>A/L & O/L</div>
                                            <div className='col-span-1'>{competition.levels[0].streams.map((stream) => <Chip label={stream.name}></Chip>)}</div>
                                            <div className='col-span-2'>{competition.timestamp}</div>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>

                </div>
                <Link to={COMPETITIONADMIN}>
                    <div className='relative py-6'>
                        <div className='bg-white w-24 flex float-right p-2 rounded-md'>
                            <a href="#">See All</a>
                            <span className='pl-3 mt-1'><BsArrowRight /></span>
                        </div>
                    </div>
                </Link>

            </div>
        </div >
    )
}

export default Landing