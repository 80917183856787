import landing1 from "./landing1.png";
import landing2 from "./landing2.png";
import landing3 from "./landing3.png";
import landing4 from "./landing4.png";
import landing5 from "./landing5.png";
import landing6 from "./landing6.png";
import landing7 from "./landing7.png";
import landing8 from "./landing8.png";
import landing9 from "./landing9.jpeg";
import landing10 from "./landing10.jpeg";
import landing11 from "./landing11.jpeg";
import landing12 from "./landing12.jpeg";
import landing13 from "./landing13.jpeg";
import landing14 from "./landing14.jpeg";




export const LandingImages = {
    landing1,
    landing2
    , landing3
    , landing4
    , landing5
    , landing6
    , landing7
    , landing8
    , landing9
    , landing10
    , landing11
    , landing12
    , landing13
    , landing14
}


