import { Student } from "../interfaces/auth.interface";
import { Competition, CompetitionApplication } from "../interfaces/competition.interface";

export const defaultStudent: Student = {
    form: "",
    city: "",
    email: "",
    registeredOn: 0,
    school: "",
    phone: "",
    name: "",
    password: "",
    age: "",

}


export const defaultCompetition: Competition = {
    name: "KC Stem competition 2022",
    levels: [
        {
            id: "ADVANCED",
            name: "Advanced Level",
            streams: [
                {
                    name: "Stream A",
                    id: "stream-a",
                    subjects: [
                        {
                            id: "chemistry",
                            name: "Chemistry",
                        }
                    ]
                },
                {
                    name: "Stream B",
                    id: "stream-b",
                    subjects: [
                        {
                            id: "maths",
                            name: "Maths",
                        }
                    ]
                },
                {
                    name: "Stream C",
                    id: "stream-c",
                    subjects: [
                        {
                            id: "zoology",
                            name: "Zoology",
                        }
                    ]
                }
            ]

        },
        {
            id: "ORDINARY",
            name: "Ordinary Level",
            streams: [
                {
                    name: "Stream A",
                    id: "stream-a",
                    subjects: [
                        {
                            id: "chemistry",
                            name: "Chemistry",
                        }
                    ]
                },
                {
                    name: "Stream B",
                    id: "stream-b",
                    subjects: [
                        {
                            id: "maths",
                            name: "Maths",
                        }
                    ]
                }
            ]

        },
    ],
    accepting: false,
    description: "",
    timestamp: ""
}



export const defaultCompApplication: CompetitionApplication = {
    name: "KC Stem competition 2022",
    level:
    {
        id: "ADVANCED",
        name: "Advanced Level",
        stream:
        {
            name: "Stream A",
            id: "stream-a",
            subjects: [
                {
                    id: "chemistry",
                    name: "Chemistry",
                }
            ]
        },

    },
    student: defaultStudent,
    timestamp: Date.now()

}


export const defaultClasses = ['FORM 1', 'FORM 2', 'FORM 3', 'FORM 4', 'FORM 5', "LOWERSIXTH", 'UPPERSIXTH',]



export const fileTypes = ['DOCUMENT', "VIDEO", "IMAGE", "AUDIO", "FOLDER"]



export const defaultChannels = ["PUBLIC", "PRIVATE"]