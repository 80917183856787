
import React from 'react'
import { BiFolder, BiImage, BiMusic, BiVideo } from 'react-icons/bi'
import { HiDocument, HiDocumentText } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { ADMINEDITRESOURCE } from '../../constants/routes.constant'
import { Resource } from '../../interfaces/resource.interface'
import { GiClapperboard } from 'react-icons/gi'

const ResourceStudentCard: React.FC<{ resource: Resource }> = ({ resource }) => {
    const navigate = useNavigate();
    const { type } = resource;

    function EditThisResource(resource: Resource) {
        navigate(ADMINEDITRESOURCE, { state: { resource } })
    }
    return (
        <a href={resource.link} target='_blank'>
            <div className="border p-3 rounded text-center">
                {type == 'DOCUMENT' && <HiDocumentText className="text-[50px] m-auto text-sky-300" />}
                {type == 'VIDEO' && <GiClapperboard className="text-[50px] m-auto text-red-400" />}
                {type == 'IMAGE' && <BiImage className="text-[50px] m-auto text-gray-500" />}
                {type == 'AUDIO' && <BiMusic className="text-[50px] m-auto text-blue-500" />}
                {type == 'FOLDER' && <BiFolder className="text-[50px] m-auto text-green-500" />}
                <div className="mt-4">
                    {resource.title}
                </div>
            </div>
        </a>
    )
}

export default ResourceStudentCard